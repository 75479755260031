import { t, h } from '@app/utils'
import {
    Card,
    Button,
    Form,
    Tile,
    Enter,
    AdminFormDropDown,
} from '@app/elements'
import * as constants from '@app/constants'

import icoClose from '@app/img/ico/close-cross.png'
import icoClosex2 from '@app/img/ico/close-cross@2x.png'

import bgIndexx2 from '@app/img/bg/btz-bckg@2x.png'

import { UserMenu } from '@app/layouts/usermenu'

module.exports = {
    initView: function () {
        let view = (state, actions) => (props, children) => (
            <div class="login-central">
                <UserMenu display={'login'}></UserMenu>
                <Card
                    classes={'btzPageCard btzBackgroundCover'}
                    kind={'datas'}
                    style={{ 'background-image': 'url(' + bgIndexx2 + ')' }}
                >
                    <Card.Header classes={'btzCard-btzHeader-btzPage'}>
                        <Enter
                            time={constants.custo.ANIM_DURATION}
                            easing={constants.custo.ANIM_EASING}
                            css={constants.custo.ANIM_CSS}
                        >
                            <dummy>
                                <Tile>
                                    <Tile.Content>
                                        <p class="btzTitle btzXl">
                                            {t('Theraflow Connect')}
                                        </p>
                                    </Tile.Content>
                                </Tile>

                                <div style="display: block">
                                    <AdminFormDropDown
                                        key="instances"
                                        name={state.targetinstance.name}
                                        document={state.targetinstance}
                                        original={state.targetinstance}
                                        list={state.targetinstance.list}
                                        onclick={actions.updateInstance}
                                    ></AdminFormDropDown>
                                </div>
                                <div style="display: block">
                                    <AdminFormDropDown
                                        key="apis"
                                        name={state.targetapi.name}
                                        document={state.targetapi}
                                        original={state.targetapi}
                                        list={state.targetapi.list}
                                        onclick={actions.updateApi}
                                    ></AdminFormDropDown>
                                </div>

                                <Form
                                    onsubmit={(event) => event.preventDefault()}
                                >
                                    <Form.Group>
                                        <Form.Input
                                            placeholder={t('adresse@mail.com')}
                                            type="email"
                                            id="email"
                                            name="email"
                                            oninput={actions.waitForLastInput}
                                            onfocus={actions.onBlur}
                                            oncreate={actions.onCreate}
                                            error={
                                                state.isTouched.email &&
                                                !state.isValid.email
                                            }
                                            classes={
                                                (state.isTouched.email
                                                    ? state.isValid.email
                                                        ? 'is-success'
                                                        : 'is-error'
                                                    : '') +
                                                ' ' +
                                                'btzColoredPlaceholder'
                                            }
                                            required
                                        />
                                        <Form.Input
                                            placeholder={t('mot de passe')}
                                            type="password"
                                            id="password"
                                            name="password"
                                            oninput={actions.waitForLastInput}
                                            onfocus={actions.onBlur}
                                            oncreate={actions.onCreate}
                                            onclick={
                                                actions.togglePasswordInput
                                            }
                                            error={
                                                state.isTouched.password &&
                                                !state.isValid.password
                                            }
                                            classes={
                                                (state.isTouched.password
                                                    ? state.isValid.password
                                                        ? 'is-success'
                                                        : 'is-error'
                                                    : '') +
                                                ' ' +
                                                'btzColoredPlaceholder'
                                            }
                                            required
                                        />
                                    </Form.Group>

                                    <Form.Group classes="btzForm-btzFooter">
                                        <Button
                                            primary
                                            flat
                                            loading={state.formIsSubmitting}
                                            cancel={
                                                !state.isValid.email &&
                                                !state.isValid.password
                                            }
                                            onclick={actions.onSubmit}
                                        >
                                            {t('Se connecter')}
                                        </Button>
                                    </Form.Group>
                                </Form>
                            </dummy>
                        </Enter>
                    </Card.Header>
                </Card>
            </div>
        )
        return view
    },
}

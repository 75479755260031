import {
    Login,
    Index,
    Content,
    Users,
    Reward,
    Gamif,
    Alfred,
    Cards,
    Programs,
    ProgramsEtp,
    Articles,
    Recipes,
    Challenges,
    Food,
    Coachs,
    Emails,
    Alerts,
    Objectives,
    CorpoWordsRh,
    CorpoCitations,
    CorpoEvaluations,
    CorpoSurveys,
    CorpoChallenges,
    Containers,
    Conversations,
    ClientErrors,
    SAValidation,
    Testers,
    Actions,
    Badges,
    Levels,
    RewActions,
    RewPromotions,
    UUsers,
    Customers,
    Nurses,
    Patients,
    Establishments,
    Scenario,
    Configuration,
    ForumFR,
    ForumUS,
    Calendars,
    Themes,
    Tag,
    Statistiques,
    Operations,
} from '@app/modules'

const allRoutes = {
    'index': {
        label: 'Accueil',
        pathname: '/',
        component: Login,
        parent: false,
    },
    'private.index': {
        label: 'Admin',
        pathname: '/index',
        component: Index,
        parent: false,
    },
    'private.content': {
        label: 'Admin',
        pathname: '/content',
        component: Content,
        parent: false,
    },
    'private.users': {
        label: 'Admin',
        pathname: '/users',
        component: Users,
        parent: false,
    },
    'private.reward': {
        label: 'Admin',
        pathname: '/reward',
        component: Reward,
        parent: false,
    },
    'private.gamif': {
        label: 'Admin',
        pathname: '/gamif',
        component: Gamif,
        parent: false,
    },
    'private.alfred': {
        label: 'Admin',
        pathname: '/alfred',
        component: Alfred,
        parent: false,
    },
    'private.cards': {
        label: 'Admin',
        pathname: '/content/cards',
        component: Cards,
        parent: false,
    },
    'private.cards.edit': {
        label: 'Admin',
        pathname: '/content/cards/:id',
        component: Cards,
        parent: false,
    },
    'private.programs': {
        label: 'programs',
        pathname: '/content/programs',
        component: Programs,
        parent: false,
    },
    'private.programs.edit': {
        label: 'programs',
        pathname: '/content/programs/:id',
        component: Programs,
        parent: false,
    },
    'private.programs.sessions': {
        label: 'programs',
        pathname: '/content/programs/:id/sessions',
        component: Programs,
        parent: false,
    },
    'private.programs.sessions.edit': {
        label: 'programs',
        pathname: '/content/programs/:id/sessions/:sid',
        component: Programs,
        parent: false,
    },
    'private.programs.sessions.steps': {
        label: 'programs',
        pathname: '/content/programs/:id/sessions/:sid/step',
        component: Programs,
        parent: false,
    },
    'private.programs.sessions.steps.edit': {
        label: 'programs',
        pathname: '/content/programs/:id/sessions/:sid/step/:ssid',
        component: Programs,
        parent: false,
    },
    'private.programs.etp': {
        label: 'programsetp',
        pathname: '/content/programs-etp',
        component: ProgramsEtp,
        parent: false,
    },
    'private.programs.etp.edit': {
        label: 'programsetp',
        pathname: '/content/programs-etp/:id',
        component: ProgramsEtp,
        parent: false,
    },
    'private.programs.etp.sessions': {
        label: 'programsetp',
        pathname: '/content/programs-etp/:id/sessions',
        component: ProgramsEtp,
        parent: false,
    },
    'private.programs.etp.sessions.edit': {
        label: 'programsetp',
        pathname: '/content/programs-etp/:id/sessions/:sid',
        component: ProgramsEtp,
        parent: false,
    },
    'private.articles': {
        label: 'Admin',
        pathname: '/content/articles',
        component: Articles,
        parent: false,
    },
    'private.articles.edit': {
        label: 'Admin',
        pathname: '/content/articles/:id',
        component: Articles,
        parent: false,
    },
    'private.recipes': {
        label: 'Admin',
        pathname: '/content/recipes',
        component: Recipes,
        parent: false,
    },
    'private.recipes.edit': {
        label: 'Admin',
        pathname: '/content/recipes/:id',
        component: Recipes,
        parent: false,
    },
    'private.challenges': {
        label: 'Admin',
        pathname: '/content/challenges',
        component: Challenges,
        parent: false,
    },
    'private.challenges.edit': {
        label: 'Admin',
        pathname: '/content/challenges/:id',
        component: Challenges,
        parent: false,
    },
    'private.aliments': {
        label: 'Admin',
        pathname: '/content/aliments',
        component: Food,
        parent: false,
    },
    'private.aliments.edit': {
        label: 'Admin',
        pathname: '/content/aliments/:id',
        component: Food,
        parent: false,
    },
    'private.coachs': {
        label: 'Admin',
        pathname: '/content/coachs',
        component: Coachs,
        parent: false,
    },
    'private.coachs.edit': {
        label: 'Admin',
        pathname: '/content/coachs/:id',
        component: Coachs,
        parent: false,
    },
    'private.emails': {
        label: 'Admin',
        pathname: '/content/emails',
        component: Emails,
        parent: false,
    },
    'private.emails.edit': {
        label: 'Admin',
        pathname: '/content/emails/:id',
        component: Emails,
        parent: false,
    },
    'private.alerts': {
        label: 'Admin',
        pathname: '/content/alerts',
        component: Alerts,
        parent: false,
    },
    'private.alerts.edit': {
        label: 'Admin',
        pathname: '/content/alerts/:id',
        component: Alerts,
        parent: false,
    },
    'private.objectives': {
        label: 'Admin',
        pathname: '/content/objectives',
        component: Objectives,
        parent: false,
    },
    'private.objectives.edit': {
        label: 'Admin',
        pathname: '/content/objectives/:id',
        component: Objectives,
        parent: false,
    },
    'private.wordsrh': {
        label: 'Admin',
        pathname: '/content/wordsrh',
        component: CorpoWordsRh,
        parent: false,
    },
    'private.wordsrh.edit': {
        label: 'Admin',
        pathname: '/content/wordsrh/:id',
        component: CorpoWordsRh,
        parent: false,
    },
    'private.citations': {
        label: 'Admin',
        pathname: '/content/citations',
        component: CorpoCitations,
        parent: false,
    },
    'private.citations.edit': {
        label: 'Admin',
        pathname: '/content/citations/:id',
        component: CorpoCitations,
        parent: false,
    },
    'private.evaluations': {
        label: 'Admin',
        pathname: '/content/evaluations',
        component: CorpoEvaluations,
        parent: false,
    },
    'private.evaluations.edit': {
        label: 'Admin',
        pathname: '/content/evaluations/:id',
        component: CorpoEvaluations,
        parent: false,
    },
    'private.evaluations.statistics': {
        label: 'Admin',
        pathname: '/content/evaluations/:id/statistics',
        component: CorpoEvaluations,
        parent: false,
    },
    'private.surveys': {
        label: 'Admin',
        pathname: '/content/surveys',
        component: CorpoSurveys,
        parent: false,
    },
    'private.surveys.edit': {
        label: 'Admin',
        pathname: '/content/surveys/:id',
        component: CorpoSurveys,
        parent: false,
    },
    'private.surveys.statistics': {
        label: 'Admin',
        pathname: '/content/surveys/:id/statistics',
        component: CorpoSurveys,
        parent: false,
    },
    'private.challenges.corpo': {
        label: 'Admin',
        pathname: '/content/corpo/challenges',
        component: CorpoChallenges,
        parent: false,
    },
    'private.challenges.corpo.edit': {
        label: 'Admin',
        pathname: '/content/corpo/challenges/:id',
        component: CorpoChallenges,
        parent: false,
    },
    'private.challenges.results': {
        label: 'Admin',
        pathname: '/content/challenges/:id/results',
        component: CorpoEvaluations,
        parent: false,
    },
    'private.containers': {
        label: 'Admin',
        pathname: '/alfred/containers',
        component: Containers,
        parent: false,
    },
    'private.containers.edit': {
        label: 'Admin',
        pathname: '/alfred/containers/:id',
        component: Containers,
        parent: false,
    },
    'private.conversations': {
        label: 'Admin',
        pathname: '/alfred/conversations',
        component: Conversations,
        parent: false,
    },
    'private.conversations.edit': {
        label: 'Admin',
        pathname: '/alfred/conversations/:id',
        component: Conversations,
        parent: false,
    },
    'private.savalidation': {
        label: 'Admin',
        pathname: '/alfred/savalidation',
        component: SAValidation,
        parent: false,
    },
    'private.savalidation.edit': {
        label: 'Admin',
        pathname: '/alfred/savalidation/:id',
        component: SAValidation,
        parent: false,
    },
    'private.testers': {
        label: 'Admin',
        pathname: '/alfred/testers',
        component: Testers,
        parent: false,
    },
    'private.testers.edit': {
        label: 'Admin',
        pathname: '/alfred/testers/:id',
        component: Testers,
        parent: false,
    },
    'private.gamif.actions': {
        label: 'Admin',
        pathname: '/gamif/actions',
        component: Actions,
        parent: false,
    },
    'private.gamif.actions.edit': {
        label: 'Admin',
        pathname: '/gamif/actions/:id',
        component: Actions,
        parent: false,
    },
    'private.gamif.badges': {
        label: 'Admin',
        pathname: '/gamif/badges',
        component: Badges,
        parent: false,
    },
    'private.gamif.badges.edit': {
        label: 'Admin',
        pathname: '/gamif/badges/:id',
        component: Badges,
        parent: false,
    },
    'private.gamif.levels': {
        label: 'Admin',
        pathname: '/gamif/levels',
        component: Levels,
        parent: false,
    },
    'private.gamif.levels.edit': {
        label: 'Admin',
        pathname: '/gamif/levels/:id',
        component: Levels,
        parent: false,
    },
    'private.reward.actions': {
        label: 'Admin',
        pathname: '/reward/actions',
        component: RewActions,
        parent: false,
    },
    'private.reward.actions.edit': {
        label: 'Admin',
        pathname: '/reward/actions/:id',
        component: RewActions,
        parent: false,
    },
    'private.reward.promotions': {
        label: 'Admin',
        pathname: '/reward/promotions',
        component: RewPromotions,
        parent: false,
    },
    'private.reward.promotions.edit': {
        label: 'Admin',
        pathname: '/reward/promotions/:id',
        component: RewPromotions,
        parent: false,
    },
    'private.users.users': {
        label: 'Admin',
        pathname: '/users/users',
        component: UUsers,
        parent: false,
    },
    'private.users.users.edit': {
        label: 'Admin',
        pathname: '/users/users/:id',
        component: UUsers,
        parent: false,
    },
    'private.users.customers': {
        label: 'Admin',
        pathname: '/users/customers',
        component: Customers,
        parent: false,
    },
    'private.users.customers.edit': {
        label: 'Admin',
        pathname: '/users/customers/:id',
        component: Customers,
        parent: false,
    },
    'private.users.customers.scenario.create': {
        label: 'Admin',
        pathname: '/users/customers/:id/scenario',
        component: Scenario,
        parent: false,
    },
    'private.users.customers.scenario.edit': {
        label: 'Admin',
        pathname: '/users/customers/:id/scenario/:idscenario',
        component: Scenario,
        parent: false,
    },
    'private.users.nurses': {
        label: 'Admin',
        pathname: '/users/nurses',
        component: Nurses,
        parent: false,
    },
    'private.users.nurses.edit': {
        label: 'Admin',
        pathname: '/users/nurses/:id',
        component: Nurses,
        parent: false,
    },
    'private.users.patients': {
        label: 'Admin',
        pathname: '/users/patients',
        component: Patients,
        parent: false,
    },
    'private.users.patients.edit': {
        label: 'Admin',
        pathname: '/users/patients/:id',
        component: Patients,
        parent: false,
    },
    'private.users.establishments': {
        label: 'Admin',
        pathname: '/users/establishments',
        component: Patients,
        parent: false,
    },
    'private.users.establishments.edit': {
        label: 'Admin',
        pathname: '/users/establishments/:id',
        component: Establishments,
        parent: false,
    },
    'private.forum.forumfr': {
        label: 'Admin',
        pathname: '/forum/forumfr',
        component: ForumFR,
        parent: false,
    },
    'private.forum.forumfr.edit': {
        label: 'Admin',
        pathname: '/forum/forumfr/:id',
        component: ForumFR,
        parent: false,
    },
    'private.forum.forumus': {
        label: 'Admin',
        pathname: '/forum/forumus',
        component: ForumUS,
        parent: false,
    },
    'private.forum.forumus.edit': {
        label: 'Admin',
        pathname: '/forum/forumus/:id',
        component: ForumUS,
        parent: false,
    },
    'private.forum.tag': {
        label: 'Admin',
        pathname: '/forum/tag',
        component: Tag,
        parent: false,
    },
    'private.forum.tag.edit': {
        label: 'Admin',
        pathname: '/forum/tag/:id',
        component: Tag,
        parent: false,
    },
    'private.calendars': {
        label: 'Admin',
        pathname: '/calendars',
        component: Calendars,
        parent: false,
    },
    'private.calendars.edit': {
        label: 'Admin',
        pathname: '/calendars/:id',
        component: Calendars,
        parent: false,
    },
    'private.themes': {
        label: 'Admin',
        pathname: '/themes',
        component: Themes,
        parent: false,
    },
    'configuration': {
        label: 'Admin',
        pathname: '/configuration',
        component: Configuration,
        parent: false,
    },
    'operations': {
        label: 'Admin',
        pathname: '/operations',
        component: Operations,
        parent: false,
    },
    'private.javascript.errors': {
        label: 'Admin',
        pathname: '/errors',
        component: ClientErrors,
        parent: false,
    },
    'private.statistiques': {
        label: 'Admin',
        pathname: '/statistiques',
        component: Statistiques,
        parent: false,
    },
    'pdf.help.corpo': {
        label: 'Admin',
        pathname: '/__pdf/pdf-help-corpo.pdf',
        component: null,
        parent: false,
    },
}

export { allRoutes }

import { t, h } from '@app/utils'
import { allRoutes, addCSRFToken } from '@app/core'
import { Link } from '@app/elements'

import { UserMenu } from '@app/layouts/usermenu'

import icoAddEntry from '@app/img/ico/add-icon.png'
import icoAddEntryx2 from '@app/img/ico/add-icon@2x.png'

import icoCards from '@app/img/ico/left-menu/ico-cartes.png'
import icoCardsx2 from '@app/img/ico/left-menu/ico-cartes@2x.png'
import icoCardsGrey from '@app/img/ico/left-menu/ico-cartes-grey.png'
import icoCardsGreyx2 from '@app/img/ico/left-menu/ico-cartes-grey@2x.png'

import icoPrograms from '@app/img/ico/left-menu/ico-prog.png'
import icoProgramsx2 from '@app/img/ico/left-menu/ico-prog@2x.png'
import icoProgramsGrey from '@app/img/ico/left-menu/ico-prog-grey.png'
import icoProgramsGreyx2 from '@app/img/ico/left-menu/ico-prog-grey@2x.png'

import icoArticles from '@app/img/ico/left-menu/ico-articles.png'
import icoArticlesx2 from '@app/img/ico/left-menu/ico-articles@2x.png'
import icoArticlesGrey from '@app/img/ico/left-menu/ico-articles-grey.png'
import icoArticlesGreyx2 from '@app/img/ico/left-menu/ico-articles-grey@2x.png'

import icoRecipes from '@app/img/ico/left-menu/ico-recettes.png'
import icoRecipesx2 from '@app/img/ico/left-menu/ico-recettes@2x.png'
import icoRecipesGrey from '@app/img/ico/left-menu/ico-recettes-grey.png'
import icoRecipesGreyx2 from '@app/img/ico/left-menu/ico-recettes-grey@2x.png'

import icoChallenges from '@app/img/ico/left-menu/ico-defis.png'
import icoChallengesx2 from '@app/img/ico/left-menu/ico-defis@2x.png'
import icoChallengesGrey from '@app/img/ico/left-menu/ico-defis-grey.png'
import icoChallengesGreyx2 from '@app/img/ico/left-menu/ico-defis-grey@2x.png'

import icoAliments from '@app/img/ico/left-menu/ico-aliments.png'
import icoAlimentsx2 from '@app/img/ico/left-menu/ico-aliments@2x.png'
import icoAlimentsGrey from '@app/img/ico/left-menu/ico-aliments-grey.png'
import icoAlimentsGreyx2 from '@app/img/ico/left-menu/ico-aliments-grey@2x.png'

import icoCoachs from '@app/img/ico/left-menu/ico-coachs.png'
import icoCoachsx2 from '@app/img/ico/left-menu/ico-coachs@2x.png'
import icoCoachsGrey from '@app/img/ico/left-menu/ico-coachs-grey.png'
import icoCoachsGreyx2 from '@app/img/ico/left-menu/ico-coachs-grey@2x.png'

import icoEmails from '@app/img/ico/left-menu/ico-message-rh.png'
import icoEmailsx2 from '@app/img/ico/left-menu/ico-message-rh@2x.png'
import icoEmailsGrey from '@app/img/ico/left-menu/ico-message-rh-grey.png'
import icoEmailsGreyx2 from '@app/img/ico/left-menu/ico-message-rh-grey@2x.png'

import icoAlertings from '@app/img/ico/left-menu/ico-alerting.png'
import icoAlertingsx2 from '@app/img/ico/left-menu/ico-alerting@2x.png'
import icoAlertingsGrey from '@app/img/ico/left-menu/ico-alerting-grey.png'
import icoAlertingsGreyx2 from '@app/img/ico/left-menu/ico-alerting-grey@2x.png'

import icoObjectives from '@app/img/ico/left-menu/ico-cartes.png'
import icoObjectivesx2 from '@app/img/ico/left-menu/ico-cartes@2x.png'
import icoObjectivesGrey from '@app/img/ico/left-menu/ico-cartes-grey.png'
import icoObjectivesGreyx2 from '@app/img/ico/left-menu/ico-cartes-grey@2x.png'

import icoUsers from '@app/img/ico/left-menu/ico-users.png'
import icoUsersx2 from '@app/img/ico/left-menu/ico-users@2x.png'
import icoUsersGrey from '@app/img/ico/left-menu/ico-users-grey.png'
import icoUsersGreyx2 from '@app/img/ico/left-menu/ico-users-grey@2x.png'

import icoCustomers from '@app/img/ico/left-menu/ico-entreprises.png'
import icoCustomersx2 from '@app/img/ico/left-menu/ico-entreprises@2x.png'
import icoCustomersGrey from '@app/img/ico/left-menu/ico-entreprises-grey.png'
import icoCustomersGreyx2 from '@app/img/ico/left-menu/ico-entreprises-grey@2x.png'

import icoRewActions from '@app/img/ico/left-menu/ico-rewards.png'
import icoRewActionsx2 from '@app/img/ico/left-menu/ico-rewards@2x.png'
import icoRewActionsGrey from '@app/img/ico/left-menu/ico-rewards-grey.png'
import icoRewActionsGreyx2 from '@app/img/ico/left-menu/ico-rewards-grey@2x.png'

import icoRewPromotions from '@app/img/ico/left-menu/ico-offers.png'
import icoRewPromotionsx2 from '@app/img/ico/left-menu/ico-offers@2x.png'
import icoRewPromotionsGrey from '@app/img/ico/left-menu/ico-offers-grey.png'
import icoRewPromotionsGreyx2 from '@app/img/ico/left-menu/ico-offers-grey@2x.png'

import icoBadges from '@app/img/ico/left-menu/ico-badges.png'
import icoBadgesx2 from '@app/img/ico/left-menu/ico-badges@2x.png'
import icoBadgesGrey from '@app/img/ico/left-menu/ico-badges-grey.png'
import icoBadgesGreyx2 from '@app/img/ico/left-menu/ico-badges-grey@2x.png'

import icoActions from '@app/img/ico/left-menu/ico-badges.png'
import icoActionsx2 from '@app/img/ico/left-menu/ico-badges@2x.png'
import icoActionsGrey from '@app/img/ico/left-menu/ico-badges-grey.png'
import icoActionsGreyx2 from '@app/img/ico/left-menu/ico-badges-grey@2x.png'

import icoLevels from '@app/img/ico/left-menu/ico-badges.png'
import icoLevelsx2 from '@app/img/ico/left-menu/ico-badges@2x.png'
import icoLevelsGrey from '@app/img/ico/left-menu/ico-badges-grey.png'
import icoLevelsGreyx2 from '@app/img/ico/left-menu/ico-badges-grey@2x.png'

import icoContainers from '@app/img/ico/left-menu/ico-badges.png'
import icoContainersx2 from '@app/img/ico/left-menu/ico-badges@2x.png'
import icoContainersGrey from '@app/img/ico/left-menu/ico-badges-grey.png'
import icoContainersGreyx2 from '@app/img/ico/left-menu/ico-badges-grey@2x.png'

import icoSAValidations from '@app/img/ico/left-menu/ico-badges.png'
import icoSAValidationsx2 from '@app/img/ico/left-menu/ico-badges@2x.png'
import icoSAValidationsGrey from '@app/img/ico/left-menu/ico-badges-grey.png'
import icoSAValidationsGreyx2 from '@app/img/ico/left-menu/ico-badges-grey@2x.png'

import icoConversations from '@app/img/ico/left-menu/ico-badges.png'
import icoConversationsx2 from '@app/img/ico/left-menu/ico-badges@2x.png'
import icoConversationsGrey from '@app/img/ico/left-menu/ico-badges-grey.png'
import icoConversationsGreyx2 from '@app/img/ico/left-menu/ico-badges-grey@2x.png'

import icoTesters from '@app/img/ico/left-menu/ico-badges.png'
import icoTestersx2 from '@app/img/ico/left-menu/ico-badges@2x.png'
import icoTestersGrey from '@app/img/ico/left-menu/ico-badges-grey.png'
import icoTestersGreyx2 from '@app/img/ico/left-menu/ico-badges-grey@2x.png'

module.exports = {
    initView: function () {
        let view = (props, actions) => (
            <div style={{ padding: '20px 0px' }}>
                <div>
                    {props.currentPage == 'content' && (
                        <ul>
                            <li class={props.page == 'cards' ? 'active' : ''}>
                                <div>
                                    <img
                                        class="activated"
                                        src={icoCards}
                                        srcset={`${icoCardsx2} 2x`}
                                        alt=""
                                    />
                                    <img
                                        class="not-activated"
                                        src={icoCardsGrey}
                                        srcset={`${icoCardsGreyx2} 2x`}
                                        alt=""
                                    />
                                </div>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes['private.cards'].pathname
                                    )}
                                >
                                    {t('Cartes')}
                                </Link>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes[
                                            'private.cards.edit'
                                        ].pathname.replace(':id', 'null')
                                    )}
                                >
                                    <img
                                        src={icoAddEntry}
                                        srcset={`${icoAddEntryx2} 2x`}
                                        alt=""
                                    />
                                </Link>
                            </li>
                            <li
                                class={
                                    [
                                        'programs',
                                        'programs sessions',
                                        'programs steps',
                                    ].indexOf(props.page) > -1
                                        ? 'active'
                                        : ''
                                }
                            >
                                <div>
                                    <img
                                        class="activated"
                                        src={icoPrograms}
                                        srcset={`${icoProgramsx2} 2x`}
                                        alt=""
                                    />
                                    <img
                                        class="not-activated"
                                        src={icoProgramsGrey}
                                        srcset={`${icoProgramsGreyx2} 2x`}
                                        alt=""
                                    />
                                </div>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes['private.programs'].pathname
                                    )}
                                >
                                    {t('Programmes')}
                                </Link>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes[
                                            'private.programs.edit'
                                        ].pathname.replace(':id', 'null')
                                    )}
                                >
                                    <img
                                        src={icoAddEntry}
                                        srcset={`${icoAddEntryx2} 2x`}
                                        alt=""
                                    />
                                </Link>
                            </li>
                            <li
                                class={props.page == 'articles' ? 'active' : ''}
                            >
                                <div>
                                    <img
                                        class="activated"
                                        src={icoArticles}
                                        srcset={`${icoArticlesx2} 2x`}
                                        alt=""
                                    />
                                    <img
                                        class="not-activated"
                                        src={icoArticlesGrey}
                                        srcset={`${icoArticlesGreyx2} 2x`}
                                        alt=""
                                    />
                                </div>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes['private.articles'].pathname
                                    )}
                                >
                                    {t('Articles')}
                                </Link>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes[
                                            'private.articles.edit'
                                        ].pathname.replace(':id', 'null')
                                    )}
                                >
                                    <img
                                        src={icoAddEntry}
                                        srcset={`${icoAddEntryx2} 2x`}
                                        alt=""
                                    />
                                </Link>
                            </li>
                            <li class={props.page == 'recipes' ? 'active' : ''}>
                                <div>
                                    <img
                                        class="activated"
                                        src={icoRecipes}
                                        srcset={`${icoRecipesx2} 2x`}
                                        alt=""
                                    />
                                    <img
                                        class="not-activated"
                                        src={icoRecipesGrey}
                                        srcset={`${icoRecipesGreyx2} 2x`}
                                        alt=""
                                    />
                                </div>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes['private.recipes'].pathname
                                    )}
                                >
                                    {t('Recettes')}
                                </Link>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes[
                                            'private.recipes.edit'
                                        ].pathname.replace(':id', 'null')
                                    )}
                                >
                                    <img
                                        src={icoAddEntry}
                                        srcset={`${icoAddEntryx2} 2x`}
                                        alt=""
                                    />
                                </Link>
                            </li>
                            <li
                                class={
                                    props.page == 'challenges' ? 'active' : ''
                                }
                            >
                                <div>
                                    <img
                                        class="activated"
                                        src={icoChallenges}
                                        srcset={`${icoChallengesx2} 2x`}
                                        alt=""
                                    />
                                    <img
                                        class="not-activated"
                                        src={icoChallengesGrey}
                                        srcset={`${icoChallengesGreyx2} 2x`}
                                        alt=""
                                    />
                                </div>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes['private.challenges'].pathname
                                    )}
                                >
                                    {t('Défis')}
                                </Link>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes[
                                            'private.challenges.edit'
                                        ].pathname.replace(':id', 'null')
                                    )}
                                >
                                    <img
                                        src={icoAddEntry}
                                        srcset={`${icoAddEntryx2} 2x`}
                                        alt=""
                                    />
                                </Link>
                            </li>
                            <li
                                class={props.page == 'aliments' ? 'active' : ''}
                            >
                                <div>
                                    <img
                                        class="activated"
                                        src={icoAliments}
                                        srcset={`${icoAlimentsx2} 2x`}
                                        alt=""
                                    />
                                    <img
                                        class="not-activated"
                                        src={icoAlimentsGrey}
                                        srcset={`${icoAlimentsGreyx2} 2x`}
                                        alt=""
                                    />
                                </div>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes['private.aliments'].pathname
                                    )}
                                >
                                    {t('Aliments')}
                                </Link>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes[
                                            'private.aliments.edit'
                                        ].pathname.replace(':id', 'null')
                                    )}
                                >
                                    <img
                                        src={icoAddEntry}
                                        srcset={`${icoAddEntryx2} 2x`}
                                        alt=""
                                    />
                                </Link>
                            </li>
                            <li class={props.page == 'coachs' ? 'active' : ''}>
                                <div>
                                    <img
                                        class="activated"
                                        src={icoCoachs}
                                        srcset={`${icoCoachsx2} 2x`}
                                        alt=""
                                    />
                                    <img
                                        class="not-activated"
                                        src={icoCoachsGrey}
                                        srcset={`${icoCoachsGreyx2} 2x`}
                                        alt=""
                                    />
                                </div>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes['private.coachs'].pathname
                                    )}
                                >
                                    {t('Coachs')}
                                </Link>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes[
                                            'private.coachs.edit'
                                        ].pathname.replace(':id', 'null')
                                    )}
                                >
                                    <img
                                        src={icoAddEntry}
                                        srcset={`${icoAddEntryx2} 2x`}
                                        alt=""
                                    />
                                </Link>
                            </li>
                            <li class={props.page == 'emails' ? 'active' : ''}>
                                <div>
                                    <img
                                        class="activated"
                                        src={icoEmails}
                                        srcset={`${icoEmailsx2} 2x`}
                                        alt=""
                                    />
                                    <img
                                        class="not-activated"
                                        src={icoEmailsGrey}
                                        srcset={`${icoEmailsGreyx2} 2x`}
                                        alt=""
                                    />
                                </div>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes['private.emails'].pathname
                                    )}
                                >
                                    {t('Emails')}
                                </Link>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes[
                                            'private.emails.edit'
                                        ].pathname.replace(':id', 'null')
                                    )}
                                >
                                    <img
                                        src={icoAddEntry}
                                        srcset={`${icoAddEntryx2} 2x`}
                                        alt=""
                                    />
                                </Link>
                            </li>
                            <li class={props.page == 'alerts' ? 'active' : ''}>
                                <div>
                                    <img
                                        class="activated"
                                        src={icoAlertings}
                                        srcset={`${icoAlertingsx2} 2x`}
                                        alt=""
                                    />
                                    <img
                                        class="not-activated"
                                        src={icoAlertingsGrey}
                                        srcset={`${icoAlertingsGreyx2} 2x`}
                                        alt=""
                                    />
                                </div>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes['private.alerts'].pathname
                                    )}
                                >
                                    {t('Alerting')}
                                </Link>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes[
                                            'private.alerts.edit'
                                        ].pathname.replace(':id', 'null')
                                    )}
                                >
                                    <img
                                        src={icoAddEntry}
                                        srcset={`${icoAddEntryx2} 2x`}
                                        alt=""
                                    />
                                </Link>
                            </li>
                            <li
                                class={
                                    props.page == 'objectives' ? 'active' : ''
                                }
                            >
                                <div>
                                    <img
                                        class="activated"
                                        src={icoObjectives}
                                        srcset={`${icoObjectivesx2} 2x`}
                                        alt=""
                                    />
                                    <img
                                        class="not-activated"
                                        src={icoObjectivesGrey}
                                        srcset={`${icoObjectivesGreyx2} 2x`}
                                        alt=""
                                    />
                                </div>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes['private.objectives'].pathname
                                    )}
                                >
                                    {t('Objectifs')}
                                </Link>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes[
                                            'private.objectives.edit'
                                        ].pathname.replace(':id', 'null')
                                    )}
                                >
                                    <img
                                        src={icoAddEntry}
                                        srcset={`${icoAddEntryx2} 2x`}
                                        alt=""
                                    />
                                </Link>
                            </li>
                        </ul>
                    )}
                    {props.currentPage == 'users' && (
                        <ul>
                            <li class={props.page == 'users' ? 'active' : ''}>
                                <div>
                                    <img
                                        class="activated"
                                        src={icoUsers}
                                        srcset={`${icoUsersx2} 2x`}
                                        alt=""
                                    />
                                    <img
                                        class="not-activated"
                                        src={icoUsersGrey}
                                        srcset={`${icoUsersGreyx2} 2x`}
                                        alt=""
                                    />
                                </div>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes['private.users.users']
                                            .pathname
                                    )}
                                >
                                    {t('Utilisateurs')}
                                </Link>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes[
                                            'private.users.users.edit'
                                        ].pathname.replace(':id', 'null')
                                    )}
                                >
                                    <img
                                        src={icoAddEntry}
                                        srcset={`${icoAddEntryx2} 2x`}
                                        alt=""
                                    />
                                </Link>
                            </li>
                            <li
                                class={
                                    props.page.indexOf('customers') > -1
                                        ? 'active'
                                        : ''
                                }
                            >
                                <div>
                                    <img
                                        class="activated"
                                        src={icoCustomers}
                                        srcset={`${icoCustomersx2} 2x`}
                                        alt=""
                                    />
                                    <img
                                        class="not-activated"
                                        src={icoCustomersGrey}
                                        srcset={`${icoCustomersGreyx2} 2x`}
                                        alt=""
                                    />
                                </div>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes['private.users.customers']
                                            .pathname
                                    )}
                                >
                                    {t('Entreprises')}
                                </Link>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes[
                                            'private.users.customers.edit'
                                        ].pathname.replace(':id', 'null')
                                    )}
                                >
                                    <img
                                        src={icoAddEntry}
                                        srcset={`${icoAddEntryx2} 2x`}
                                        alt=""
                                    />
                                </Link>
                            </li>
                        </ul>
                    )}
                    {props.currentPage == 'reward' && (
                        <ul>
                            <li
                                class={
                                    props.page == 'rewactions' ? 'active' : ''
                                }
                            >
                                <div>
                                    <img
                                        class="activated"
                                        src={icoRewActions}
                                        srcset={`${icoRewActionsx2} 2x`}
                                        alt=""
                                    />
                                    <img
                                        class="not-activated"
                                        src={icoRewActionsGrey}
                                        srcset={`${icoRewActionsGreyx2} 2x`}
                                        alt=""
                                    />
                                </div>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes['private.reward.actions']
                                            .pathname
                                    )}
                                >
                                    {t('Actions')}
                                </Link>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes[
                                            'private.reward.actions.edit'
                                        ].pathname.replace(':id', 'null')
                                    )}
                                >
                                    <img
                                        src={icoAddEntry}
                                        srcset={`${icoAddEntryx2} 2x`}
                                        alt=""
                                    />
                                </Link>
                            </li>
                            <li
                                class={
                                    props.page == 'rewpromotions'
                                        ? 'active'
                                        : ''
                                }
                            >
                                <div>
                                    <img
                                        class="activated"
                                        src={icoRewPromotions}
                                        srcset={`${icoRewPromotionsx2} 2x`}
                                        alt=""
                                    />
                                    <img
                                        class="not-activated"
                                        src={icoRewPromotionsGrey}
                                        srcset={`${icoRewPromotionsGreyx2} 2x`}
                                        alt=""
                                    />
                                </div>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes['private.reward.promotions']
                                            .pathname
                                    )}
                                >
                                    {t('Promotions')}
                                </Link>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes[
                                            'private.reward.promotions.edit'
                                        ].pathname.replace(':id', 'null')
                                    )}
                                >
                                    <img
                                        src={icoAddEntry}
                                        srcset={`${icoAddEntryx2} 2x`}
                                        alt=""
                                    />
                                </Link>
                            </li>
                        </ul>
                    )}
                    {props.currentPage == 'gamif' && (
                        <ul>
                            <li class={props.page == 'badges' ? 'active' : ''}>
                                <div>
                                    <img
                                        class="activated"
                                        src={icoBadges}
                                        srcset={`${icoBadgesx2} 2x`}
                                        alt=""
                                    />
                                    <img
                                        class="not-activated"
                                        src={icoBadgesGrey}
                                        srcset={`${icoBadgesGreyx2} 2x`}
                                        alt=""
                                    />
                                </div>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes['private.gamif.badges']
                                            .pathname
                                    )}
                                >
                                    {t('Badges')}
                                </Link>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes[
                                            'private.gamif.badges.edit'
                                        ].pathname.replace(':id', 'null')
                                    )}
                                >
                                    <img
                                        src={icoAddEntry}
                                        srcset={`${icoAddEntryx2} 2x`}
                                        alt=""
                                    />
                                </Link>
                            </li>
                            <li class={props.page == 'actions' ? 'active' : ''}>
                                <div>
                                    <img
                                        class="activated"
                                        src={icoActions}
                                        srcset={`${icoActionsx2} 2x`}
                                        alt=""
                                    />
                                    <img
                                        class="not-activated"
                                        src={icoActionsGrey}
                                        srcset={`${icoActionsGreyx2} 2x`}
                                        alt=""
                                    />
                                </div>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes['private.gamif.actions']
                                            .pathname
                                    )}
                                >
                                    {t('Actions')}
                                </Link>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes[
                                            'private.gamif.actions.edit'
                                        ].pathname.replace(':id', 'null')
                                    )}
                                >
                                    <img
                                        src={icoAddEntry}
                                        srcset={`${icoAddEntryx2} 2x`}
                                        alt=""
                                    />
                                </Link>
                            </li>
                            <li class={props.page == 'levels' ? 'active' : ''}>
                                <div>
                                    <img
                                        class="activated"
                                        src={icoLevels}
                                        srcset={`${icoLevelsx2} 2x`}
                                        alt=""
                                    />
                                    <img
                                        class="not-activated"
                                        src={icoLevelsGrey}
                                        srcset={`${icoLevelsGreyx2} 2x`}
                                        alt=""
                                    />
                                </div>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes['private.gamif.levels']
                                            .pathname
                                    )}
                                >
                                    {t('Niveau')}
                                </Link>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes[
                                            'private.gamif.levels.edit'
                                        ].pathname.replace(':id', 'null')
                                    )}
                                >
                                    <img
                                        src={icoAddEntry}
                                        srcset={`${icoAddEntryx2} 2x`}
                                        alt=""
                                    />
                                </Link>
                            </li>
                        </ul>
                    )}
                    {props.currentPage == 'alfred' && (
                        <ul>
                            <li
                                class={
                                    props.page == 'containers' ? 'active' : ''
                                }
                            >
                                <div>
                                    <img
                                        class="activated"
                                        src={icoContainers}
                                        srcset={`${icoContainersx2} 2x`}
                                        alt=""
                                    />
                                    <img
                                        class="not-activated"
                                        src={icoContainersGrey}
                                        srcset={`${icoContainersGreyx2} 2x`}
                                        alt=""
                                    />
                                </div>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes['private.containers'].pathname
                                    )}
                                >
                                    {t('Containers')}
                                </Link>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes[
                                            'private.containers'
                                        ].pathname.replace(':id', 'null')
                                    )}
                                >
                                    <img
                                        src={icoAddEntry}
                                        srcset={`${icoAddEntryx2} 2x`}
                                        alt=""
                                    />
                                </Link>
                            </li>
                            <li
                                class={
                                    props.page == 'savalidation' ? 'active' : ''
                                }
                            >
                                <div>
                                    <img
                                        class="activated"
                                        src={icoSAValidations}
                                        srcset={`${icoSAValidationsx2} 2x`}
                                        alt=""
                                    />
                                    <img
                                        class="not-activated"
                                        src={icoSAValidationsGrey}
                                        srcset={`${icoSAValidationsGreyx2} 2x`}
                                        alt=""
                                    />
                                </div>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes['private.savalidation']
                                            .pathname
                                    )}
                                >
                                    {t('SA Validation')}
                                </Link>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes[
                                            'private.savalidation'
                                        ].pathname.replace(':id', 'null')
                                    )}
                                >
                                    <img
                                        src={icoAddEntry}
                                        srcset={`${icoAddEntryx2} 2x`}
                                        alt=""
                                    />
                                </Link>
                            </li>
                            <li
                                class={
                                    props.page == 'conversations'
                                        ? 'active'
                                        : ''
                                }
                            >
                                <div>
                                    <img
                                        class="activated"
                                        src={icoConversations}
                                        srcset={`${icoConversationsx2} 2x`}
                                        alt=""
                                    />
                                    <img
                                        class="not-activated"
                                        src={icoConversationsGrey}
                                        srcset={`${icoConversationsGreyx2} 2x`}
                                        alt=""
                                    />
                                </div>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes['private.conversations']
                                            .pathname
                                    )}
                                >
                                    {t('Conversations')}
                                </Link>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes[
                                            'private.conversations'
                                        ].pathname.replace(':id', 'null')
                                    )}
                                >
                                    <img
                                        src={icoAddEntry}
                                        srcset={`${icoAddEntryx2} 2x`}
                                        alt=""
                                    />
                                </Link>
                            </li>
                            <li class={props.page == 'testers' ? 'active' : ''}>
                                <div>
                                    <img
                                        class="activated"
                                        src={icoTesters}
                                        srcset={`${icoTestersx2} 2x`}
                                        alt=""
                                    />
                                    <img
                                        class="not-activated"
                                        src={icoTestersGrey}
                                        srcset={`${icoTestersGreyx2} 2x`}
                                        alt=""
                                    />
                                </div>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes['private.testers'].pathname
                                    )}
                                >
                                    {t('Tester')}
                                </Link>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes[
                                            'private.testers'
                                        ].pathname.replace(':id', 'null')
                                    )}
                                >
                                    <img
                                        src={icoAddEntry}
                                        srcset={`${icoAddEntryx2} 2x`}
                                        alt=""
                                    />
                                </Link>
                            </li>
                        </ul>
                    )}
                    <div
                        style={{
                            position: 'absolute',
                            bottom: '20px',
                            left: '65px',
                        }}
                    >
                        <UserMenu display={'connected'}></UserMenu>
                    </div>
                </div>
            </div>
        )
        return view
    },
}

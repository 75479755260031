import { h } from '@app/utils'
import { Grid } from '.'

describe('Grid element', () => {
    test('grid element appears', () => {
        expect(<Grid />).toEqual(<div class="container grid-xl"></div>)
    })

    test('grid row element appears', () => {
        expect(
            <Grid>
                <Grid.Row>Content</Grid.Row>
            </Grid>
        ).toEqual(
            <div class="container grid-xl">
                <div class="columns">Content</div>
            </div>
        )
    })
})

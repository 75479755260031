export { default as Login } from './Login'
export { default as AdminIndex } from './AdminIndex'

export { default as Alfred } from './Alfred'
export { default as Containers } from './Alfred/pages/Containers'
export { default as Conversations } from './Alfred/pages/Conversations'
export { default as SAValidation } from './Alfred/pages/SAValidation'
export { default as Testers } from './Alfred/pages/Testers'

export { default as Content } from './Content'
export { default as Alerts } from './Content/pages/Alerts'
export { default as Articles } from './Content/pages/Articles'
export { default as Cards } from './Content/pages/Cards'
export { default as Challenges } from './Content/pages/Challenges'
export { default as Coachs } from './Content/pages/Coachs'
export { default as Emails } from './Content/pages/Emails'
export { default as Food } from './Content/pages/Food'
export { default as Objectives } from './Content/pages/Objectives'
export { default as Programs } from './Content/pages/Programs'
export { default as Recipes } from './Content/pages/Recipes'
export { default as Calendars } from './Content/pages/Calendars'
export { default as CorpoWordsRh } from './Content/pages/CorpoWordsRh'
export { default as CorpoCitations } from './Content/pages/CorpoCitations'
export { default as CorpoEvaluations } from './Content/pages/CorpoEvaluations'
export { default as CorpoSurveys } from './Content/pages/CorpoSurveys'
export { default as CorpoChallenges } from './Content/pages/CorpoChallenges'
export { default as Themes } from './Content/pages/Themes'
export { default as ClientErrors } from './Content/pages/ClientErrors'

export { default as Forum } from './Forum'
export { default as ForumFR } from './Forum/pages/ForumFR'
export { default as ForumUS } from './Forum/pages/ForumUS'
export { default as Tag } from './Forum/pages/Tag'

export { default as Gamif } from './Gamif'
export { default as Actions } from './Gamif/pages/Actions'
export { default as Badges } from './Gamif/pages/Badges'
export { default as Levels } from './Gamif/pages/Levels'

export { default as Reward } from './Reward'
export { default as RewActions } from './Reward/pages/Actions'
export { default as RewPromotions } from './Reward/pages/Promotions'

export { default as Users } from './Users'
export { default as Customers } from './Users/pages/Customers'
export { default as UUsers } from './Users/pages/Users'
export { default as Scenario } from './Users/pages/Customers/pages/Scenario'
export { default as Nurses } from './Users/pages/Nurses'
export { default as Patients } from './Users/pages/Patients'
export { default as Establishments } from './Users/pages/Establishments'

export { default as Configuration } from './Configuration'
export { default as Statistiques } from './Statistiques'
export { default as Operations } from './Operations'

/*
Settings that are responsible for application' configuration,
and can be overriden per item basis by using the corresponding
variable environment, e.g.:
`API_URL="https://domain.tld" yarn start`
*/

export { dynLoad } from './dyn-load'
export { iePolyfills } from './iePolyfills'
// export { clientErrorsHandler } from './clientErrorsHandler';
export {
    $,
    getClosest,
    getMinHourFromMinutes,
    getQueryVariable,
    markedDownContent,
} from './tools'
export {
    createCookie,
    readCookie,
    eraseCookie,
    scrollTo,
    isEmail,
} from './tools'
export {
    convertMetrics,
    convertMetricsLabels,
    getUser,
    setUser,
    logoutAndRedirect,
} from './tools'
export {
    capitalize,
    compareByKey,
    trimSpecialChar,
    castToNumberOfFloat,
    castToBoolean,
} from './tools'
export { isMobile, isMobileOrTablet, redirectToWebOrApp } from './tools'
export {
    addCSRFToken,
    checkForValidCSRFToken,
    createAndSaveCSRFToken,
} from './tools'
export {
    getDateObjFromYMD,
    getYMDfromDateObj,
    isDefined,
    createUTCDate,
} from './tools'
export {
    isInteger,
    isFloat,
    fromNow,
    deepCopy,
    createQueryMessage,
    sortObject,
    sortObjectBy,
    isObjEmpty,
    parseMobileLeadingZero,
} from './tools'

export { allRoutes } from './routes'
export { settings } from './settings'

import { settings } from '@app/core/settings'
import { handleErrors } from '@app/api/errors'
import { queueQueries } from '@app/api/controlleur'

const { API_URL } = settings

function getCustomersByName(search) {
    return queueQueries(
        `${API_URL}/admin/customers/autocomplete/${search}/`,
        'GET'
    )
}

function getCustomerChildsByNameAndParentId(search, id) {
    return queueQueries(
        `${API_URL}/admin/customers/autocomplete/${search}/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        { parent: id }
    )
}

function getCustomerAsMother(search) {
    return queueQueries(
        `${API_URL}/admin/customers/autocomplete/${search}/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        { noParent: true }
    )
}

function getCustomersById(id) {
    return queueQueries(`${API_URL}/admin/customers/${id}/`, 'GET')
}

function getCustomersLifenReference() {
    return queueQueries(
        `${API_URL}/admin/customers/lifen-databases-references/`,
        'GET'
    )
}

function updateCustomers(id, data) {
    return queueQueries(
        `${API_URL}/admin/customers/${id}/`,
        'PUT',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}

function deleteCustomer(id) {
    return queueQueries(`${API_URL}/admin/customers/${id}/`, 'DELETE')
}

export const Customers = {
    getCustomersByName,
    getCustomerChildsByNameAndParentId,
    getCustomerAsMother,
    getCustomersById,
    getCustomersLifenReference,
    updateCustomers,
    deleteCustomer,
}

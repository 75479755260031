import { t, h, Component } from '@app/utils'
import { Cardline, Share } from '@app/api'
import { Modal } from '@app/elements'
import './index.scss'

import ImgShowMore from './social/soc-more.png'
import ImgShowMorex2 from './social/soc-more@2x.png'

import ImgFb from './social/soc-facebook.png'
import ImgFbx2 from './social/soc-facebook@2x.png'

import ImgTw from './social/soc-twitter.png'
import ImgTwx2 from './social/soc-twitter@2x.png'

import ImgMail from './social/soc-mail.png'
import ImgMailx2 from './social/soc-mail@2x.png'

import ImgFav from './social/soc-favorite.png'
import ImgFavx2 from './social/soc-favorite@2x.png'

import ImgFavOn from './social/soc-favorite-on.png'
import ImgFavOnx2 from './social/soc-favorite-on@2x.png'

const Social = Component(
    {
        showMore: false,
        favorite: false,
        baseUrl: '',
        idtimeline: null,
        idcard: null,
        idfavorite: null,
        title: '',
        kindcard: '',
        rendering: false,
        displayedon: '',
    },
    {
        onComponentCreate: (props) => (state) => {
            state.idtimeline = props.idtimeline || null
            state.idcard = props.id || null
            state.idfavorite = props.idfavorite || null
            state.favorite = props.favorite || false
            state.kindcard = props.kindcard || ''
            state.title = props.title || ''
        },
        onComponentUpdate: (props) => (state) => {
            state.idfavorite = props.idfavorite || null
            state.favorite = props.favorite || false
        },
        showMore: (event) => (state, actions) => {
            event.preventDefault()
            event.stopPropagation()

            actions.setShowMore()
        },
        setShowMore: () => (state) => ({ showMore: true }),
        shareFb: (props) => (state, actions) => {
            Share.shareThisCard(state.kindcard, state.idcard, 'facebook').then(
                (res) => {
                    actions.openPopup({
                        kind: 'facebook',
                        title: state.title,
                        hashtag: null,
                        res: res,
                    })
                }
            )
        },
        shareTwitter: (props) => (state, actions) => {
            Share.shareThisCard(state.kindcard, state.idcard, 'twitter').then(
                (res) => {
                    actions.openPopup({
                        kind: 'twitter',
                        title: state.title,
                        hashtag: 'Betterise',
                        res: res,
                    })
                }
            )
        },
        shareMail: (props) => (state, actions) => {
            Share.shareThisCard(state.kindcard, state.idcard, 'email').then(
                (res) => {
                    let parsedTitle = actions.getParsedTitle(state.kindcard)
                    let parsedUrl = actions.getParsedUrl({
                        url: res,
                        title: state.title,
                    })
                    actions.openPopup({
                        kind: 'email',
                        title: parsedTitle,
                        hashtag: null,
                        res: parsedUrl,
                    })
                }
            )
        },
        getParsedTitle: ({ kindcard }) => {
            var title = 'Cela peu t‘interesser!'
            if (kindcard === 'advice') {
                title = t('Un conseil qui peut t‘interesser')
            } else if (kindcard === 'recipe') {
                title = t('Une recette qui peut t‘interesser')
            } else if (kindcard === 'article') {
                title = t('Un article qui peut t‘interesser')
            } else if (kindcard === 'citation') {
                title = t('Une citation qui peut t‘interesser')
            }
            return title
        },
        getParsedUrl: ({ url, title }) => {
            if (url.data === false) {
                url.data = []
                url.data.url = title + ' ' + t('Je l‘ai lu sur Betterise')
            } else {
                url.data.url =
                    title +
                    ' ' +
                    t('Je l‘ai lu sur Betterise') +
                    ' : ' +
                    url.data.url
            }
            return url
        },
        favorite: (props) => (state, actions) => {
            if (props.displayedon !== 'favorite') {
                actions.setFavorited()
            }

            if (state.idtimeline === null || state.idcard === null) {
                return false
            }

            if (!state.favorite) {
                Cardline.favoriteThisCard(state.idtimeline, state.idcard).then(
                    (res) => {
                        actions.setFavoriteId(res.data.id)
                        actions.propagateFavoriteState(props)
                    }
                )
            } else {
                actions.favoriteDeletionPropagated()
                actions.propagateFavoriteState(props)
            }
        },
        setFavoriteId: (newState) => (state) => ({
            idfavorite: newState,
        }),
        setFavorited: () => (state) => ({
            favorite: !state.favorite,
        }),
        propagateFavoriteState: (props) => (state, actions) => {
            props.propagate(state.idfavorite)
        },
        favoriteDeletionPropagated: (props) => (state, actions) => {
            Cardline.unfavoriteThisCard(state.idfavorite)
        },
        openPopup:
            ({ kind, title, hashtag, res }) =>
            (state, actions) => {
                var url = res.data.url
                if (kind === 'facebook') {
                    window.open(
                        'https://www.facebook.com/sharer.php?u=' +
                            encodeURIComponent(url),
                        'PartageSocial',
                        'width=500,height=500,toolbar=no,scrollbars=yes,location=0,statusbar=no,menubar=no,resizable=yes,top:50,left:50'
                    )
                } else if (kind === 'twitter') {
                    window.open(
                        'https://twitter.com/intent/tweet?url=' +
                            encodeURIComponent(url) +
                            '&text=' +
                            encodeURIComponent(title) +
                            '&hashtags=' +
                            encodeURIComponent(hashtag)
                    )
                } else if (kind === 'email') {
                    window.location.href =
                        'mailto:?subject=' +
                        encodeURIComponent(title) +
                        '&body=' +
                        encodeURIComponent(url)
                }
            },
        rendering: () => (state) => ({
            rendering: !state.rendering,
        }),
    },
    (state, actions) => (props, children) => (
        <div
            class="btzSocial"
            id={props.id}
            idtimeline={props.idtimeline}
            idfavorite={props.idfavorite}
            data-id={props.did}
            data-tag={props.tag}
            data-value={state.count}
            data-kind={state.kind}
        >
            {props.displayedon == 'timeline' && (
                <button
                    class="btzSocial-btzEntry"
                    onclick={() => {
                        actions.favorite(props)
                    }}
                    data-favorite={state.favorite}
                >
                    <img
                        src={ImgFav}
                        srcset={`${ImgFavx2} 2x`}
                        alt=""
                        class="btzSocial-btzEntry-btzFavOff"
                    />
                    <img
                        src={ImgFavOn}
                        srcset={`${ImgFavOnx2} 2x`}
                        alt=""
                        class="btzSocial-btzEntry-btzFavOn"
                    />
                </button>
            )}

            {props.displayedon == 'favorite' && (
                <div
                    style={{
                        'display': 'inline-block',
                        'vertical-align': 'top',
                    }}
                >
                    <Modal
                        content={''}
                        confirm={true}
                        confirmConfirm={() => {
                            actions.favorite(props)
                        }}
                        title={t('Suppression du favori')}
                        description={t(
                            'Confirmez-vous la suppression de ce favori ?'
                        )}
                    >
                        <button
                            class="btzSocial-btzEntry"
                            data-favorite={state.favorite}
                        >
                            <img
                                src={ImgFav}
                                srcset={`${ImgFavx2} 2x`}
                                alt=""
                                class="btzSocial-btzEntry-btzFavOff"
                            />
                            <img
                                src={ImgFavOn}
                                srcset={`${ImgFavOnx2} 2x`}
                                alt=""
                                class="btzSocial-btzEntry-btzFavOn"
                            />
                        </button>
                    </Modal>
                </div>
            )}

            <div class="btzSocial-btzSocials-Wrapper">
                <button
                    class="btzSocial-btzEntry btzSocial-btzEntry-displayMode"
                    onclick={actions.showMore}
                    data-displayed={state.showMore}
                >
                    <img
                        src={ImgShowMore}
                        srcset={`${ImgShowMorex2} 2x`}
                        alt=""
                        class=""
                        style={{ margin: '-4px 0 0 2px' }}
                    />
                </button>
                <div
                    class="btzSocial-btzSocials-Wrapper-btzSocialDisplayed"
                    data-displayed={state.showMore}
                >
                    <button
                        class="btzSocial-btzEntry"
                        onclick={actions.shareFb}
                    >
                        <img
                            src={ImgFb}
                            srcset={`${ImgFbx2} 2x`}
                            alt=""
                            class=""
                        />
                    </button>
                    <button
                        class="btzSocial-btzEntry"
                        onclick={actions.shareTwitter}
                    >
                        <img
                            src={ImgTw}
                            srcset={`${ImgTwx2} 2x`}
                            alt=""
                            class=""
                        />
                    </button>
                    <button
                        class="btzSocial-btzEntry"
                        onclick={actions.shareMail}
                    >
                        <img
                            src={ImgMail}
                            srcset={`${ImgMailx2} 2x`}
                            alt=""
                            class=""
                        />
                    </button>
                </div>
            </div>
        </div>
    ),
    'social'
)

export { Social }

import { settings } from '@app/core/settings'
import { handleErrors } from '@app/api/errors'
import { queueQueries } from '@app/api/controlleur'

const { API_URL } = settings

function getTrackersList() {
    return queueQueries(`${API_URL}/trackers-list/`, 'GET')
}

export const Trackers = {
    getTrackersList,
}

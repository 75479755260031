import { h } from '@app/utils'
import { User } from '@app/api'
import { isDefined, deepCopy } from '@app/core'

module.exports = {
    initAction: function () {
        const action = {
            onComponentCreate: (props) => (state, actions) => {
                let params = props === null ? null : props.match.params
                if (
                    params === null ||
                    params === undefined ||
                    (params.id !== undefined && params.id === 'null')
                ) {
                    actions.onComponentUpdate(props)
                }
            },
            onComponentUpdate: (props) => (state, actions) => {
                let params = props === null ? null : props.match.params
                if (params === null || params === undefined) {
                    actions.setDisplay('table')
                    actions.setInitialized(true)
                } else {
                    let id = params.id
                    if (id) {
                        if (isDefined(id) && id !== 'null') {
                            actions.setEditId(id)
                            actions.setDisplay('form')
                            actions.setInitialized(true)
                            User.getUserByKind(state.kind, id).then((res) => {
                                actions.setDocument(
                                    deepCopy(state.document.form)
                                )
                                if (isDefined(res.data) && !res.data.isError) {
                                    actions.parseAndSetDocument(
                                        deepCopy(res.data)
                                    )
                                    actions.setOriginalDocument(
                                        deepCopy(res.data)
                                    )
                                }
                            })
                        } else {
                            actions.setEditId(null)
                            actions.setDisplay('form')
                            actions.setInitialized(true)
                            actions.setDocument(deepCopy(state.form))
                            actions.setOriginalDocument(deepCopy(state.form)) // to survey, its new
                        }
                    } else {
                        actions.setDisplay('table')
                        actions.setInitialized(true)
                    }
                }
            },
            parseAndSetDocument: (doc) => (state, actions) => {
                // let document = JSON.parse(JSON.stringify(state.document));
                let document = state.document
                let sliderType = '',
                    sliderTag = '',
                    trgField = ''
                //
                if (isDefined(state.forbiddenDeletion)) {
                    if (
                        isDefined(state.forbiddenDeletion) &&
                        Array.isArray(state.forbiddenDeletion.dependsOn)
                    ) {
                        if (
                            state.forbiddenDeletion.dependsOn.filter((on) =>
                                isDefined(on.is)
                            ).length === 0 ||
                            (state.forbiddenDeletion.dependsOn.filter((on) =>
                                isDefined(on.is)
                            ).length > 0 &&
                                // && isDefined(state.forbiddenDeletion.dependsOn.filter((on) => { return (isDefined(doc[on.field]) && (String(doc[on.field]) === String(on.is))) })[0])
                                state.forbiddenDeletion.dependsOn.reduce(
                                    (acc, on) => {
                                        if (
                                            isDefined(doc[on.field]) &&
                                            String(doc[on.field]) !==
                                                String(on.is)
                                        ) {
                                            acc.push(on)
                                        }
                                        //
                                        return acc
                                    },
                                    []
                                ).length === 0)
                        ) {
                            //
                        } else {
                            actions.setCanBeDeleted(false)
                        }
                    }
                    //
                }
                //
                for (var field in document) {
                    //
                    var fieldName = field
                    if (
                        isDefined(document[fieldName]) &&
                        isDefined(document[fieldName].name)
                    ) {
                        fieldName = document[fieldName].name
                    }
                    var mappedFieldName = fieldName
                    if (mappedFieldName === 'mappedEcs') {
                        mappedFieldName = 'ecs'
                    }
                    if (isDefined(doc)) {
                        if (
                            isDefined(document[fieldName]) &&
                            document[fieldName].type === 'booleanString' &&
                            isDefined(doc[fieldName])
                        ) {
                            let booleanStringValue = doc[fieldName]
                            if (
                                booleanStringValue === 0 ||
                                booleanStringValue === 'false' ||
                                booleanStringValue === false
                            ) {
                                booleanStringValue = '0'
                            } else if (
                                booleanStringValue === 1 ||
                                booleanStringValue === 'true' ||
                                booleanStringValue === true
                            ) {
                                booleanStringValue = '1'
                            }
                            doc[fieldName] = booleanStringValue
                        }
                        field = doc[fieldName]
                        if (field === undefined) {
                            field = fieldName.split('.')
                            if (doc[field[0]] !== undefined) {
                                let tmpField = field.reduce(
                                    (a, b) => (a !== null ? a[b] : a),
                                    doc
                                )
                                if (
                                    !isDefined(tmpField) &&
                                    isDefined(doc[field[0]]) &&
                                    Array.isArray(doc[field[0]])
                                ) {
                                    tmpField = doc[field[0]].filter(
                                        (f) => f.name === field[1]
                                    )
                                    if (isDefined(tmpField[0])) {
                                        tmpField = +tmpField[0].value
                                    } else {
                                        field = ''
                                    }
                                }
                                if (
                                    tmpField !== '' &&
                                    ((Array.isArray(tmpField) &&
                                        tmpField.length > 0) ||
                                        !Array.isArray(tmpField))
                                ) {
                                    field = tmpField
                                } else {
                                    field = ''
                                }
                            } else {
                                field = ''
                                continue
                            }
                        }
                    }
                    if (isDefined(field)) {
                        if (field[0] !== undefined) {
                            if (field[0].datas !== undefined) {
                                trgField = 'datas' // for evaluation
                            } else if (field[0].content !== undefined) {
                                trgField = 'content' // [steps], used by recipe
                            }
                        }
                        // base on depends field
                        if (
                            document[mappedFieldName] &&
                            document[mappedFieldName] !== null &&
                            document[mappedFieldName] !== undefined
                        ) {
                            if (
                                document[mappedFieldName].type ===
                                    'translation' &&
                                field[0] !== undefined &&
                                field[0][trgField] !== undefined
                            ) {
                                let lang = '',
                                    name = '',
                                    value = ''
                                let newValues = {}
                                for (var entry in field) {
                                    sliderType = field[entry].type || ''
                                    sliderTag = field[entry].tag || ''

                                    for (var dat in field[entry][trgField]) {
                                        if (
                                            field[entry][trgField][dat] === null
                                        ) {
                                            name = trgField
                                            value = field[entry][trgField][dat]
                                            lang = dat
                                        } else {
                                            lang =
                                                field[entry][trgField][dat]
                                                    .lang || dat
                                            name =
                                                field[entry][trgField][dat]
                                                    .name || trgField
                                            value =
                                                field[entry][trgField][dat]
                                                    .value ||
                                                field[entry][trgField][dat]
                                        }
                                        if (name === undefined) {
                                            name = trgField
                                            value = field[entry][trgField][dat]
                                            lang = dat
                                        }
                                        if (
                                            field[entry]['title'] !==
                                                undefined &&
                                            field[entry]['title'][dat] !==
                                                undefined
                                        ) {
                                            value = {}
                                            value[trgField] =
                                                field[entry][trgField][dat]
                                            value['title'] =
                                                field[entry]['title'][dat]
                                        }
                                        if (newValues[lang] === undefined) {
                                            newValues[lang] = []
                                        }
                                        if (name !== '' && name !== undefined) {
                                            if (
                                                value === null ||
                                                value === undefined
                                            ) {
                                                value = ''
                                            }
                                            if (
                                                sliderTag === null ||
                                                sliderTag === undefined
                                            ) {
                                                sliderTag = ''
                                            }
                                            newValues[lang].push({
                                                name: name,
                                                value: value,
                                                tag: sliderTag,
                                            })
                                        }
                                    }
                                }
                                document[mappedFieldName].value = newValues
                            } else if (mappedFieldName === 'customerModules') {
                                let refField = document[mappedFieldName]
                                for (var refChoice in refField.choiceList) {
                                    refField.choiceList[refChoice].checked =
                                        field[refField.choiceList[refChoice].id]
                                    if (
                                        isDefined(
                                            refField.choiceList[refChoice].list
                                        )
                                    ) {
                                        if (
                                            isDefined(
                                                field[
                                                    refField.choiceList[
                                                        refChoice
                                                    ].id + 'Settings'
                                                ]
                                            )
                                        ) {
                                            for (var refSetting in field[
                                                refField.choiceList[refChoice]
                                                    .id + 'Settings'
                                            ]) {
                                                if (
                                                    !Array.isArray(
                                                        refField.choiceList[
                                                            refChoice
                                                        ].list
                                                    ) &&
                                                    isDefined(
                                                        refField.choiceList[
                                                            refChoice
                                                        ].tempList
                                                    )
                                                ) {
                                                    refField.choiceList[
                                                        refChoice
                                                    ].tempList.push({
                                                        id: field[
                                                            refField.choiceList[
                                                                refChoice
                                                            ].id + 'Settings'
                                                        ][refSetting].name,
                                                        checked:
                                                            field[
                                                                refField
                                                                    .choiceList[
                                                                    refChoice
                                                                ].id +
                                                                    'Settings'
                                                            ][refSetting]
                                                                .status,
                                                        bydefault:
                                                            field[
                                                                refField
                                                                    .choiceList[
                                                                    refChoice
                                                                ].id +
                                                                    'Settings'
                                                            ][refSetting]
                                                                .default,
                                                    })
                                                } else {
                                                    for (var fieldSetting in refField
                                                        .choiceList[refChoice]
                                                        .list) {
                                                        if (
                                                            field[
                                                                refField
                                                                    .choiceList[
                                                                    refChoice
                                                                ].id +
                                                                    'Settings'
                                                            ][refSetting]
                                                                .name ===
                                                            refField.choiceList[
                                                                refChoice
                                                            ].list[fieldSetting]
                                                                .key
                                                        ) {
                                                            refField.choiceList[
                                                                refChoice
                                                            ].list[
                                                                fieldSetting
                                                            ].checked =
                                                                field[
                                                                    refField
                                                                        .choiceList[
                                                                        refChoice
                                                                    ].id +
                                                                        'Settings'
                                                                ][
                                                                    refSetting
                                                                ].status
                                                            refField.choiceList[
                                                                refChoice
                                                            ].list[
                                                                fieldSetting
                                                            ].bydefault =
                                                                field[
                                                                    refField
                                                                        .choiceList[
                                                                        refChoice
                                                                    ].id +
                                                                        'Settings'
                                                                ][
                                                                    refSetting
                                                                ].default
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                document[mappedFieldName].refChoice =
                                    refField.choiceList
                            } else {
                                if (mappedFieldName === 'roles') {
                                    if (Array.isArray(field)) {
                                        field = field[0]
                                    }
                                }
                                document[mappedFieldName].value = field
                                for (var dField in document) {
                                    // if ((mappedFieldName.indexOf('customer') === -1) && (document[dField].name === mappedFieldName)) {
                                    if (
                                        document[dField].name ===
                                        mappedFieldName
                                    ) {
                                        document[dField].value = field
                                    }
                                }
                            }
                            //
                            let atLoad = document[mappedFieldName].atLoad
                            if (atLoad) {
                                if (atLoad.readOnly) {
                                    if (
                                        atLoad.readOnly.ifExistingEntry &&
                                        doc.id
                                    ) {
                                        document[
                                            mappedFieldName
                                        ].readonly = true
                                    }
                                }
                                if (atLoad.nullish) {
                                    if (
                                        atLoad.nullish.ifExistingEntry &&
                                        doc.id
                                    ) {
                                        document[mappedFieldName].value = null
                                    }
                                }
                            }
                        }
                    }
                    //
                    if (
                        mappedFieldName === 'language' ||
                        mappedFieldName === 'locale'
                    ) {
                        document[mappedFieldName].value = (
                            doc[mappedFieldName] || doc['language']
                        ).slice(0, 2)
                    }
                    //
                }
                if (sliderType !== '') {
                    document.formsType.value = sliderType
                }
                document.original = doc
                actions.setDocument(document)
            },
            setCurrentUrl: (newState) => (state) => ({
                currentUrl: newState,
            }),
            setCanBeDeleted: (newState) => (state) => ({
                canBeDeleted: newState,
            }),
            setState:
                ({ ns, key, value }) =>
                (state) => ({
                    [ns]: { ...state[ns], ...{ [key]: value } },
                }),
            setDisplay: (newState) => (state) => ({
                display: newState,
            }),
            setEditId: (newState) => (state) => ({
                editId: newState,
            }),
            setDocument: (newState) => (state) => ({
                document: newState,
            }),
            setOriginalDocument: (newState) => (state) => ({
                originaldocument: newState,
            }),
            setInitialized: (newState) => (state) => ({
                initialized: newState,
            }),
        }
        return action
    },
}

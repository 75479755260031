import { settings } from '@app/core/settings'
import { handleErrors } from '@app/api/errors'
import { queueQueries } from '@app/api/controlleur'

const { API_URL } = settings

function getTags(page = 0, sorts = {}, filters = {}) {
    return queueQueries(
        `${API_URL}/admin/topics/tags/search/${page}/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        { sort: sorts, ...filters }
    )
}
function deleteTag(id) {
    return queueQueries(`${API_URL}/admin/topics/tags/${id}/`, 'DELETE')
}

function getForumFR(page = 0, sorts = {}, filters = {}) {
    return queueQueries(
        `${API_URL}/admin/topics/search/${page}/?lang=fr`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        { lang: 'fr', sort: sorts, ...filters }
    )
}
function getForumUS(page = 0, sorts = {}, filters = {}) {
    return queueQueries(
        `${API_URL}/admin/topics/search/${page}/?lang=en_US`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        { lang: 'en_US', sort: sorts, ...filters }
    )
}

function moderateAnswer(id, data) {
    return queueQueries(
        `${API_URL}/admin/topics/${id}/`,
        'PUT',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        { ...data }
    )
}
function moderateTopic(id) {
    return queueQueries(
        `${API_URL}/admin/topics/${id}/`,
        'PUT',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        { moderated: true }
    )
}
function resetTopic(id) {
    return queueQueries(
        `${API_URL}/admin/topics/${id}/`,
        'PUT',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        { resetAbuses: true }
    )
}

function addForumFR(data) {
    return queueQueries(
        `${API_URL}/admin/topics/?lang=fr`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        { lang: 'fr', ...data }
    )
}
function addForumUS(data) {
    return queueQueries(
        `${API_URL}/admin/topics/?lang=en_US`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        { lang: 'en_US', ...data }
    )
}

function editForumFR(id, data) {
    return queueQueries(
        `${API_URL}/admin/topics/${id}/`,
        'PUT',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function editForumUS(id, data) {
    return queueQueries(
        `${API_URL}/admin/topics/${id}/`,
        'PUT',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}

function setForumTopicVisible(id) {
    return queueQueries(
        `${API_URL}/admin/topics/${id}/`,
        'PUT',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        { shown: 1 }
    )
}
function setForumTopicInvisible(id) {
    return queueQueries(
        `${API_URL}/admin/topics/${id}/`,
        'PUT',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        { shown: 0 }
    )
}

function getUserTopics(id, pid = 0) {
    return queueQueries(`${API_URL}/admin/users/${id}/topics/${pid}`, 'POST')
}
function getUserTopicAnswers(id, pid = 0) {
    return queueQueries(
        `${API_URL}/admin/users/${id}/topicAnswers/${pid}`,
        'POST'
    )
}

export const Forum = {
    getTags,
    deleteTag,
    getForumFR,
    getForumUS,
    moderateAnswer,
    moderateTopic,
    resetTopic,
    addForumFR,
    addForumUS,
    editForumFR,
    editForumUS,
    getUserTopics,
    getUserTopicAnswers,
    setForumTopicVisible,
    setForumTopicInvisible,
}

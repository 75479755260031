import { settings } from '@app/core/settings'
import { handleErrors } from '@app/api/errors'
import { queueQueries, executeQuery } from '@app/api/controlleur'

const { API_URL } = settings

function getClientErrors(page = 0, sorts = {}, filters = {}) {
    return queueQueries(
        `${API_URL}/admin/javascript-errors/search/${page}/`,
        'GET'
    )
}
function getClientError(id) {
    return queueQueries(`${API_URL}/admin/javascript-errors/${id}/`, 'GET')
}
function deleteClientError(id) {
    return queueQueries(`${API_URL}/admin/javascript-errors/${id}/`, 'DELETE')
}

export const ClientErrors = {
    getClientErrors,
    getClientError,
    deleteClientError,
}

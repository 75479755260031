import { t, h, Component } from '@app/utils'
import { debounce } from 'hyperapp-fx'
import { Settings, AutoComplete } from '@app/api'
import { isDefined } from '@app/core'
import { Link, Form, Button, Convert, MessagePopup } from '@app/elements'
import './index.scss'

import { settings } from '@app/core/settings'
const { API_URL } = settings

const AdminFormDownloadDropDown = Component(
    {
        ulDisplayed: false,
        ulDisplayedType: false,
        ulDisplayedHello: false,
        ulDisplayedAdminMessage: false,
        list: [],
        typeList: [],
        idList: [],
        helloList: [],
        selected: null,
        lang: {},
        type: {},
        id: {},
        hello: {},
        downloading: false,
        searchId: '',
        key: '',
        extracard: '',
        extrasubcard: '',
        btnLabel: 'Télécharger',
        isActive: false,
        token: '',
        special: '',
        batchFile: null,
        batchResult: '',
        messageActive: false,
        messageKind: 'success',
        messageContent: '',
        adminMessage: null,
        baseApi: '',
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.btnLabel) {
                state.btnLabel = props.btnLabel
            }
            if (props.isActive) {
                state.isActive = props.isActive
            }
            if (props.special) {
                state.special = props.special
            }
            actions.onComponentUpdate(props)
            actions.defineList(props)
            if (props.definition) {
                if (props.definition.key) {
                    actions.setKey(props.definition.key)
                }

                if (props.definition.defaultType.key !== undefined) {
                    actions.setExtracard('/' + props.definition.defaultType.key)
                }
                actions.defineListType(props)
                actions.defineListHello(props)
                actions.setLang(props.definition.defaultLang)
                actions.setType(props.definition.defaultType)
            }
            actions.setToken(
                encodeURIComponent(localStorage.getItem('apitoken'))
            )
            //
            let baseApi = API_URL
            if (baseApi.indexOf('localhost') === -1) {
                let overrideapi = localStorage.getItem('overrideapi')
                let overrideinstance = localStorage.getItem('overrideinstance')
                if (isDefined(overrideapi) && overrideapi !== 'null') {
                    if (overrideinstance === 'release') {
                        overrideapi = overrideapi.replace(
                            '-api',
                            '-api-release'
                        )
                    }
                    console.log('baseApi : ', baseApi, overrideapi)
                    baseApi = 'https://' + overrideapi
                }
            }
            actions.setBaseApi(baseApi)
        },
        onComponentUpdate: (props) => (state, actions) => {
            if (props.adminMessage) {
                let adminMessage = props.adminMessage
                adminMessage.key = adminMessage.type
                adminMessage.label =
                    adminMessage.type === 0 ? t('Information') : t('Alerte')
                actions.setAdminMessage(adminMessage)
            }
        },
        launchAction: () => (state, actions) => {
            if (['batch', 'patients'].indexOf(state.special) > -1) {
                let name = ''
                let doctorEmail = ''
                if (state.special === 'batch') {
                    name = state.type.label
                    if (name) {
                        name = state.typeList.filter((f) => f.label === name)
                        if (name.length > 0) {
                            name = name[0].name
                        }
                    }
                    if (
                        state.batchFile === null ||
                        name === '' ||
                        name === undefined ||
                        name === null ||
                        (+state.type.key === 16 && state.extrasubcard === '')
                    ) {
                        actions.setMessageKind('error')
                        let message = t(
                            'Une erreur est survenue, veuillez réessayer ou contacter l‘assistance.'
                        )
                        if (state.batchFile === null) {
                            message = t('Veuillez renseigner un fichier.')
                        } else if (
                            name === '' ||
                            name === undefined ||
                            name === null
                        ) {
                            message = t(
                                'Aucun nom trouvé pour ce type d‘envoi, veuillez contacter l‘assistance'
                            )
                        } else if (
                            +state.type.key === 16 &&
                            state.extrasubcard === ''
                        ) {
                            message = t(
                                'Veuillez renseigner un type de carte Bonjour.'
                            )
                        }
                        actions.setMessageKind('error')
                        actions.setMessageContent(message)
                        actions.displaySuccessMessage(true)
                        return false
                    }
                } else if (state.special === 'patients') {
                    doctorEmail = document.querySelector(
                        'input[name="emailDoctorInsert"]'
                    ).value
                    console.log('doctorEmail : ', doctorEmail)
                    let message = ''
                    if (doctorEmail === '') {
                        message = t("Veuillez renseigner l'email du docteur.")
                        // eslint-disable-next-line
                    } else if (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(doctorEmail) === false) {
                        message = t('Veuillez renseigner un email valide.')
                    }
                    if (message !== '') {
                        actions.setMessageKind('error')
                        actions.setMessageContent(message)
                        actions.displaySuccessMessage(true)
                        return false
                    }
                }
                actions.setDownloading(true)
                console.log('doctorEmail : ', doctorEmail, state.special)
                if (state.special === 'batch') {
                    Settings.sendBatch(
                        state.batchFile,
                        state.lang.key,
                        name,
                        state.extrasubcard
                    ).then((res) => {
                        let batchResult = ''
                        if (isDefined(res.data) && !res.data.isError) {
                            if (res.data.command) {
                                batchResult += res.data.command
                            }
                            if (res.data.out) {
                                batchResult +=
                                    '\n\n' +
                                    res.data.out
                                        .filter((f) => f !== '')
                                        .join('\n')
                            }
                        }
                        actions.setBatchResult(batchResult)
                        actions.setDownloading(false)
                    })
                } else if (state.special === 'patients') {
                    Settings.sendPatients(state.batchFile, doctorEmail).then(
                        (res) => {
                            actions.setMessageKind('success')
                            actions.setMessageContent(
                                t('Fichier envoyé avec succés')
                            )
                            actions.displaySuccessMessage(true)
                        }
                    )
                } else if (state.special === 'answers') {
                    Settings.sendSurveyAnswer(state.batchFile).then((res) => {
                        actions.setMessageKind('success')
                        actions.setMessageContent(
                            t('Fichier envoyé avec succés')
                        )
                        actions.displaySuccessMessage(true)
                    })
                }
            } else if (state.special === 'adminmessage') {
                if (state.adminMessage.content === '') {
                    actions.setMessageKind('error')
                    actions.setMessageContent(
                        t('Veuillez renseigner un message admin.')
                    )
                    actions.displaySuccessMessage(true)
                    return false
                }
                actions.setDownloading(true)
                Settings.setAdminMessage(
                    state.adminMessage.content,
                    state.adminMessage.key
                ).then(() => {
                    actions.setDownloading(false)
                })
            }
        },
        selectThisLang: (el) => (state, actions) => {
            actions.setLang({ label: el.value, key: el.key })
            actions.toggleUl(false)
        },
        selectThisType: (el) => (state, actions) => {
            if (el.type !== undefined) {
                actions.setExtracard('/' + el.type)
            } else {
                actions.setExtracard('')
            }
            actions.setType({ label: el.label, key: el.type })
            actions.toggleUlType(false)
        },
        selectThisId: (el) => (state, actions) => {
            if (el.id !== undefined) {
                actions.setExtracard('/' + el.id)
            } else {
                actions.setExtracard('')
            }
            actions.setId({ label: el.name, key: el.id })
            actions.toggleUlId(false)
        },
        selectThisHello: (el) => (state, actions) => {
            if (el.key !== undefined) {
                actions.setExtraSubcard('/' + el.key)
            } else {
                actions.setExtraSubcard('')
            }
            actions.setHello({ label: el.value, key: el.key })
            actions.toggleUlHello(false)
        },
        selectThisMessageKind: (el) => (state, actions) => {
            actions.setAdminMessage({
                label: el.value,
                key: el.key,
                content: state.adminMessage.content,
            })
            actions.toggleUlAdminMessage(false)
        },
        defineList: (props) => (state, actions) => {
            let list = props.list
            list = Object.keys(list).map((i) => list[i])
            if (isDefined(list)) {
                actions.setList(list)
            }
        },
        setList: (newState) => (state) => ({
            list: newState,
        }),
        setToken: (newState) => (state) => ({
            token: newState,
        }),
        setKey: (newState) => (state) => ({
            key: newState,
        }),
        setExtracard: (newState) => (state) => ({
            extracard: newState,
        }),
        setExtraSubcard: (newState) => (state) => ({
            extrasubcard: newState,
        }),
        defineListType: (props) => (state, actions) => {
            let list = props.definition.typeList
            if (list !== undefined) {
                list = Object.keys(list).map((i) => list[i])
                if (isDefined(list)) {
                    actions.setListType(list)
                }
            }
        },
        setListType: (newState) => (state) => ({
            typeList: newState,
        }),
        defineListHello: (props) => (state, actions) => {
            let list = props.helloList
            if (list !== undefined) {
                list = Object.keys(list).map((i) => list[i])
                if (isDefined(list)) {
                    actions.setListHello(list)
                }
            }
        },
        setListId: (newState) => (state) => ({
            idList: newState,
        }),
        setListHello: (newState) => (state) => ({
            helloList: newState,
        }),
        setLang: (newState) => (state) => ({
            lang: newState,
        }),
        setType: (newState) => (state) => ({
            type: newState,
        }),
        setId: (newState) => (state) => ({
            id: newState,
        }),
        setHello: (newState) => (state) => ({
            hello: newState,
        }),
        setAdminMessage: (newState) => (state) => ({
            adminMessage: newState,
        }),
        toggleUl: (newState) => (state) => ({
            ulDisplayed: newState,
        }),
        toggleUlType: (newState) => (state) => ({
            ulDisplayedType: newState,
        }),
        toggleUlId: (newState) => (state) => ({
            ulDisplayedId: newState,
        }),
        toggleUlHello: (newState) => (state) => ({
            ulDisplayedHello: newState,
        }),
        toggleUlAdminMessage: (newState) => (state) => ({
            ulDisplayedAdminMessage: newState,
        }),
        setDownloading: (newState) => (state) => ({
            downloading: newState,
        }),
        setSearchId: (newState) => (state) => ({
            searchId: newState,
        }),
        setBaseApi: (newState) => (state) => ({
            baseApi: newState,
        }),
        defineBatchFile: (el) => (state, actions) => {
            let file = el.target.files
            if (!file || +file.length === 0) {
                actions.setMessageKind('error')
                actions.setMessageContent(
                    t('Veuillez renseigner un fichier à uploader.')
                )
                actions.displaySuccessMessage(true)
                return false
            } else {
                let filename = file[0].name
                // eslint-disable-next-line
                if (/[^a-zA-Z0-9\.\-\_]/.test(filename)) {
                    actions.setMessageKind('error')
                    actions.setMessageContent(
                        t('Le nom du fichier comporte des caractères interdit.')
                    )
                    actions.displaySuccessMessage(true)
                    return false
                }
            }
            let data = {}
            data.f = file
            actions.setBatchResult('')
            actions.setBatchFile(data)
        },
        setBatchFile: (newState) => (state) => ({
            batchFile: newState,
        }),
        setBatchResult: (newState) => (state) => ({
            batchResult: newState,
        }),
        displaySuccessMessage: (newState) => (state) => ({
            messageActive: newState,
        }),
        setMessageKind: (newState) => (state) => ({
            messageKind: newState,
        }),
        setMessageContent: (newState) => (state) => ({
            messageContent: newState,
        }),
        onUpdate: (value) => (state, actions) => {
            actions.setSearchId(value)
            if (value === '') {
                actions.setListId([])
                actions.toggleUlId(false)
            } else {
                let completeKind = state.key
                switch (completeKind) {
                    case 'program':
                        AutoComplete.getPrograms(value).then((res) => {
                            if (isDefined(res.data) && !res.data.isError) {
                                actions.setListId(res.data)
                            }
                            actions.toggleUlId(true)
                        })
                        break
                    default:
                        console.log(
                            'Sorry, we are out of ' + completeKind + '.'
                        )
                }
            }
        },
        waitForLastInput: ({ target }) =>
            debounce(500, 'onUpdate', target.value),
    },
    (state, actions) => (props, children) => (
        <div>
            {state.messageActive && (
                <MessagePopup
                    kind={state.messageKind}
                    active={'active'}
                    duration={3000}
                    durationCallback={() =>
                        actions.displaySuccessMessage(false)
                    }
                    message={state.messageContent}
                ></MessagePopup>
            )}

            {state.special &&
                ['batch', 'patients', 'answers'].indexOf(state.special) >
                    -1 && (
                    <div style={{ margin: '0 0 15px 0' }}>
                        <input
                            type="file"
                            name=""
                            onchange={(el) => actions.defineBatchFile(el)}
                            style={{ padding: '5px 10px' }}
                        />
                    </div>
                )}
            {['adminmessage', 'patients', 'answers'].indexOf(state.special) ===
                -1 && (
                <div
                    class="sup-custom-select sup-custom-select-wide-one"
                    tabindex="0"
                    onblur={() => {
                        actions.toggleUl(false)
                    }}
                >
                    <div
                        class="sup-custom-select-default-label"
                        onclick={() => {
                            actions.toggleUl(!state.ulDisplayed)
                        }}
                    >
                        {state.lang.label}
                    </div>
                    <div
                        class="sup-drop-down"
                        onclick={() => {
                            actions.toggleUl(!state.ulDisplayed)
                        }}
                    ></div>
                    <ul
                        class="sup-custom-select-ul"
                        style={{
                            display: state.ulDisplayed ? 'block' : 'none',
                        }}
                    >
                        {state.list.map((l) => (
                            <li
                                class="sup-custom-select-li"
                                onclick={() => {
                                    actions.selectThisLang(l)
                                }}
                            >
                                {l.value}
                            </li>
                        ))}
                    </ul>
                </div>
            )}

            {props.definition !== undefined &&
                props.definition.availableType && (
                    <div
                        class="sup-custom-select sup-custom-select-wide-one"
                        style={{ 'margin-left': '15px' }}
                        tabindex="0"
                        onblur={() => {
                            actions.toggleUlType(false)
                        }}
                    >
                        <div
                            class="sup-custom-select-default-label"
                            onclick={() => {
                                actions.toggleUlType(!state.ulDisplayedType)
                            }}
                        >
                            {state.type.label}
                        </div>
                        <div
                            class="sup-drop-down"
                            onclick={() => {
                                actions.toggleUlType(!state.ulDisplayedType)
                            }}
                        ></div>
                        <ul
                            class="sup-custom-select-ul"
                            style={{
                                display: state.ulDisplayedType
                                    ? 'block'
                                    : 'none',
                            }}
                        >
                            {state.typeList.map((l) => (
                                <li
                                    class="sup-custom-select-li"
                                    onclick={() => {
                                        actions.selectThisType(l)
                                    }}
                                >
                                    {l.label}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}

            {props.definition !== undefined && props.definition.availableId && (
                <div
                    class="sup-custom-select sup-custom-select-wide-one sup-custom-select-id"
                    style={{ 'margin-left': '15px' }}
                    tabindex="0"
                    onblur={() => {
                        actions.toggleUlId(false)
                    }}
                >
                    <input
                        type={'text'}
                        class="sup-custom-select-default-label"
                        onclick={() => {
                            actions.toggleUlId(!state.ulDisplayedId)
                        }}
                        oninput={actions.waitForLastInput}
                        value={
                            state.id.label !== undefined
                                ? state.id.label
                                : state.searchId
                        }
                    />
                    <ul
                        class="sup-custom-select-ul"
                        style={{
                            display: state.ulDisplayedId ? 'block' : 'none',
                        }}
                    >
                        {state.idList.map((l) => (
                            <li
                                class="sup-custom-select-li"
                                onclick={() => {
                                    actions.selectThisId(l)
                                }}
                            >
                                {l.name}
                            </li>
                        ))}
                    </ul>
                </div>
            )}

            {props.definition !== undefined &&
                state.type.key !== undefined &&
                +state.type.key === 16 && (
                    <div
                        class="sup-custom-select sup-custom-select-wide-one"
                        style={{ 'margin-left': '15px' }}
                        tabindex="0"
                        onblur={() => {
                            actions.toggleUlHello(false)
                        }}
                    >
                        <div
                            class="sup-custom-select-default-label"
                            onclick={() => {
                                actions.toggleUlHello(!state.ulDisplayedHello)
                            }}
                        >
                            {state.hello.label}
                        </div>
                        <div
                            class="sup-drop-down"
                            onclick={() => {
                                actions.toggleUlHello(!state.ulDisplayedHello)
                            }}
                        ></div>
                        <ul
                            class="sup-custom-select-ul"
                            style={{
                                display: state.ulDisplayedHello
                                    ? 'block'
                                    : 'none',
                            }}
                        >
                            {state.helloList.map((l) => (
                                <li
                                    class="sup-custom-select-li"
                                    onclick={() => {
                                        actions.selectThisHello(l)
                                    }}
                                >
                                    {l.value}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            {state.special && state.special === 'batch' && (
                <div class="sup-admin-download-entry">
                    <p
                        class="sup-admin-download-entry-label"
                        style={{ 'margin-top': '25px' }}
                    >
                        {t('Résultat')}
                    </p>
                    <div
                        class="sup-textarea"
                        style={{
                            'margin-left': '0px',
                            'display': 'block',
                            'width': '420px',
                        }}
                    >
                        <div
                            style={{ height: '150px' }}
                            innerHTML={
                                <Convert
                                    separator={''}
                                    is_xhtml={true}
                                    type="nl2br"
                                >
                                    {state.batchResult}
                                </Convert>
                            }
                        ></div>
                    </div>
                </div>
            )}

            {state.special && state.special === 'patients' && (
                <div class="btzAdminForm">
                    <form class="btzForm sup-custom-form">
                        <div class="sup-group-form email input-email">
                            <div style="display: inline-block; vertical-align: top;">
                                <label class="sup-label">
                                    {t(
                                        'Email du médecin qui réalisera les imports'
                                    )}
                                </label>
                            </div>
                            <input
                                name="emailDoctorInsert"
                                type="email"
                                placeholder={t('email du médecin')}
                            />
                        </div>
                    </form>
                </div>
            )}

            {state.special &&
                state.special === 'adminmessage' &&
                state.adminMessage !== null && (
                    <div>
                        <div
                            class="sup-custom-select sup-custom-select-wide-one"
                            tabindex="0"
                            onblur={() => {
                                actions.toggleUlAdminMessage(false)
                            }}
                        >
                            <div
                                class="sup-custom-select-default-label"
                                onclick={() => {
                                    actions.toggleUlAdminMessage(
                                        !state.ulDisplayedAdminMessage
                                    )
                                }}
                            >
                                {state.adminMessage.label}
                            </div>
                            <div
                                class="sup-drop-down"
                                onclick={() => {
                                    actions.toggleUlAdminMessage(
                                        !state.ulDisplayedAdminMessage
                                    )
                                }}
                            ></div>
                            <ul
                                class="sup-custom-select-ul"
                                style={{
                                    display: state.ulDisplayedAdminMessage
                                        ? 'block'
                                        : 'none',
                                }}
                            >
                                {state.list.map((l) => (
                                    <li
                                        class="sup-custom-select-li"
                                        onclick={() => {
                                            actions.selectThisMessageKind(l)
                                        }}
                                    >
                                        {l.value}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div
                            class="sup-admin-download-entry"
                            style={{
                                'display': 'inline-block',
                                'margin-left': '15px',
                            }}
                        >
                            <div
                                class="sup-textarea"
                                style={{
                                    'margin-left': '0px',
                                    'display': 'block',
                                    'width': '420px',
                                }}
                            >
                                <textarea
                                    style={{ height: '150px' }}
                                    oninput={(el) => {
                                        actions.setAdminMessage({
                                            label: state.adminMessage.label,
                                            key: state.adminMessage.key,
                                            content: el.target.value,
                                        })
                                    }}
                                >
                                    {state.adminMessage.content}
                                </textarea>
                            </div>
                        </div>
                    </div>
                )}

            <Form.Group
                classes="btzForm-btzFooter btzForm-btzFooter-btzSubmits btzForm-btzTop-btzSubmits"
                style={{ 'text-align': 'right' }}
            >
                {props.definition !== undefined &&
                props.definition.directLink === true &&
                ['batch', 'patients', 'answers'].indexOf(state.special) ===
                    -1 ? (
                    <div>
                        {state.key === 'program' ? (
                            <Link
                                target={'_blank'}
                                to={`${state.baseApi}/admin/settings/download/${state.key}${state.extracard}/${state.lang.key}/?xtoken=${state.token}`}
                                class=""
                            >
                                {t(state.btnLabel)}
                            </Link>
                        ) : (
                            <Link
                                target={'_blank'}
                                to={`${state.baseApi}/admin/settings/download/${state.key}${state.extracard}${state.extrasubcard}/${state.lang.key}/?xtoken=${state.token}`}
                                class=""
                            >
                                {t(state.btnLabel)}
                            </Link>
                        )}
                    </div>
                ) : (
                    <Button
                        primary
                        flat
                        active={state.isActive}
                        loading={state.downloading}
                        onclick={actions.launchAction}
                    >
                        {t(state.btnLabel)}
                    </Button>
                )}
            </Form.Group>
        </div>
    ),
    'adminformdownloaddropdown'
)

export { AdminFormDownloadDropDown }

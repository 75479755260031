import { h } from '@app/utils'
import { Toast } from '.'

describe('Toast element', () => {
    it('should return a well structured vNode', () => {
        expect(<Toast />).toEqual(
            <div class="toast">
                <button class="btn btn-clear float-right"></button>
            </div>
        )
    })
})

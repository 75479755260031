import { dynLoad } from '@app/core'

import * as base from '@app/constants/constants-default.js'

// export const custo = {...base, ...dynLoad('constants', 'constants', '')};
export const custo = Object.assign(
    {},
    base,
    dynLoad('constants', 'constants', '')
)

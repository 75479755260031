import { t, h, Component } from '@app/utils'
import { Link } from '@app/elements'
import {
    getYMDfromDateObj,
    allRoutes,
    addCSRFToken,
    isDefined,
} from '@app/core'
import { User, Forum } from '@app/api'
import './index.scss'

import * as constants from '@app/constants'

const AdminFormBlockAsTab = Component(
    {
        document: null,
        active: '',
        template: '',
        values: [],
        parsedKpis: [],
        parsedConnexions: [],
        forumTopic: [],
        forumTopicAnswers: [],
        optIdEntry: null,
        optDisplayed: false,
        confirmAction: false,
        originaldocument: null,
        keysToAvoid: [
            'id',
            'period',
            '__isInitialized__',
            'symbol',
            'current',
            'date',
            'meteo',
            'timezone',
            'time_zone',
            'timezone_type',
            'consecutives',
            'meteo1',
            'meteo2',
            'meteo3',
            'meteo4',
            'meteo5',
            'meteo6',
            'meteo01',
            'meteo02',
            'meteo03',
            'meteo04',
            'meteo05',
            'meteo06',
        ],
        pageTopic: 0,
        pageTopicAnswer: 0,
        pagination: 25,
        token: null,
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.originaldocument) {
                state.originaldocument = props.originaldocument
            }
            if (props.document) {
                if (
                    isDefined(props.document.template) &&
                    props.document.template.value
                ) {
                    actions.setTemplate(props.document.template.value)
                }
                if (props.document.tabs.value) {
                    actions.setActive(props.document.tabs.value[0].key)
                    actions.setValues(props.document.tabs.value)
                    for (var tab in props.document.tabs.value) {
                        if (
                            props.document.tabs.value[tab].marker ===
                                'listing-kpi' &&
                            state.parsedKpis.length === 0
                        ) {
                            User.getUsersProfile(
                                props.originaldocument.id
                            ).then((res) => {
                                if (
                                    isDefined(res.data) &&
                                    !res.data.isError &&
                                    state.parsedKpis.length === 0
                                ) {
                                    actions.parseKPIS({
                                        data: res.data,
                                        previousKey: '',
                                    })
                                    actions.setParsedKpis(
                                        state.parsedKpis.sort((a, b) =>
                                            a.label > b.label ? 1 : -1
                                        )
                                    )
                                }
                            })
                        } else if (
                            props.document.tabs.value[tab].marker ===
                                'listing-connexion' &&
                            state.parsedConnexions.length === 0
                        ) {
                            User.getUsersLicenses(
                                props.originaldocument.id
                            ).then((res) => {
                                if (
                                    isDefined(res.data) &&
                                    !res.data.isError &&
                                    state.parsedConnexions.length === 0
                                ) {
                                    let licenses = Object.keys(
                                        constants.custo.LICENSES_STATUS
                                    ).map(
                                        (i) =>
                                            constants.custo.LICENSES_STATUS[i]
                                    )
                                    let current = null,
                                        action = '',
                                        date = '',
                                        ref = ''
                                    for (var st in res.data) {
                                        current = licenses.filter(
                                            (f) =>
                                                +f.key === +res.data[st].status
                                        )[0]
                                        if (current && isDefined(current)) {
                                            if (
                                                current.label !== undefined &&
                                                current.label !== null
                                            ) {
                                                action = current.label
                                            } else {
                                                action = ''
                                            }
                                            if (
                                                res.data[st].date !==
                                                    undefined &&
                                                res.data[st].date !== null
                                            ) {
                                                date = res.data[st].date
                                            } else {
                                                date = ''
                                            }
                                            if (
                                                res.data[st].reference !==
                                                    undefined &&
                                                res.data[st].reference !== null
                                            ) {
                                                ref = res.data[st].reference
                                            } else {
                                                ref = ''
                                            }
                                        }
                                        state.parsedConnexions.push({
                                            actions: action,
                                            date: date,
                                            ref: ref,
                                        })
                                    }
                                    actions.setParsedConnexions(
                                        state.parsedConnexions
                                    )
                                }
                            })
                        } else if (
                            props.document.tabs.value[tab].marker ===
                            'listing-licenses'
                        ) {
                            // User.getUsersProfile(props.originaldocument.id).then((res) => {
                            //     if (state.parsedKpis.length === 0) {
                            //         actions.parseKPIS({data: res.data, previousKey: ''});
                            //         actions.setParsedKpis(state.parsedKpis)
                            //     }
                            // });
                        } else {
                            actions.updateTable(
                                props.document.tabs.value[tab].marker
                            )
                        }
                    }
                }
            }
            actions.setToken(
                encodeURIComponent(localStorage.getItem('apitoken'))
            )
        },
        onComponentUpdate: (props) => (state, actions) => {
            //
        },
        updateTable: (marker) => (state, actions) => {
            if (marker === 'listing-forum') {
                Forum.getUserTopics(
                    state.originaldocument.id,
                    state.pageTopic
                ).then((res) => {
                    if (isDefined(res.data) && !res.data.isError) {
                        actions.setForumTopic(res.data)
                    }
                })
            } else if (marker === 'listing-comment') {
                Forum.getUserTopicAnswers(
                    state.originaldocument.id,
                    state.pageTopicAnswer
                ).then((res) => {
                    if (isDefined(res.data) && !res.data.isError) {
                        actions.setForumTopicAnswers(res.data)
                    }
                })
            }
        },
        setActiveBlock: (tab) => (state, actions) => {
            actions.setActive(tab.key)
        },
        setActive: (newState) => (state) => ({
            active: newState,
        }),
        setValues: (newState) => (state) => ({
            values: newState,
        }),
        setPageTopic: (newState) => (state) => ({
            pageTopic: newState,
        }),
        setPageTopicAnswer: (newState) => (state) => ({
            pageTopicAnswer: newState,
        }),
        setParsedKpis: (newState) => (state) => ({
            parsedKpis: newState,
        }),
        setParsedConnexions: (newState) => (state) => ({
            parsedConnexions: newState,
        }),
        setForumTopic: (newState) => (state) => ({
            forumTopic: newState,
        }),
        setForumTopicAnswers: (newState) => (state) => ({
            forumTopicAnswers: newState,
        }),
        setToken: (newState) => (state) => ({
            token: newState,
        }),
        setTemplate: (newState) => (state) => ({
            template: newState,
        }),
        sendAndRedirect:
            ({
                props,
                from,
                to,
                refId = null,
                refSId = null,
                refSSId = null,
                refType = null,
            }) =>
            (state, actions) => {
                console.log('sendAndRedirect : ', props, from, to)
                if (props.sendFormAndRedirect !== undefined) {
                    props.sendFormAndRedirect({
                        from: from,
                        to: to,
                        refId: refId,
                        refSId: refSId,
                        refSSId: refSSId,
                        refType: refType,
                    })
                }
            },
        deleteEntryAndUpdate:
            ({
                props,
                from,
                to,
                refId = null,
                refSId = null,
                refSSId = null,
            }) =>
            (state, actions) => {
                console.log('deleteEntryAndUpdate : ', props)
                if (props.deleteEntryAndUpdate !== undefined) {
                    console.log(
                        'deleteEntryAndUpdate : ',
                        from,
                        to,
                        refId,
                        refSId,
                        refSSId
                    )
                    props.deleteEntryAndUpdate({
                        from: from,
                        to: to,
                        refId: refId,
                        refSId: refSId,
                        refSSId: refSSId,
                    })
                }
            },
        parseKPIS:
            ({ data, previousKey }) =>
            (state, actions) => {
                var curKey = '',
                    curDat = null
                for (var dat in data) {
                    if (state.keysToAvoid.indexOf(dat) > -1) {
                        continue
                    }
                    curKey = previousKey + dat
                    curDat = data[dat]

                    if (curDat !== null) {
                        if (typeof curDat === 'object') {
                            actions.parseKPIS({
                                data: curDat,
                                previousKey: curKey + '.',
                            })
                        } else {
                            state.parsedKpis.push({
                                label: curKey,
                                value: curDat,
                            })
                        }
                    }
                }
                return false
            },
        toggleOptions: (identry) => (state, actions) => {
            if (!state.optDisplayed || identry !== state.optIdEntry) {
                actions.setConfirmAction(false)
            }
            if (identry === state.optIdEntry || !state.optDisplayed) {
                actions.displayOptions(
                    state.optDisplayed === true ? false : true
                )
            }
            actions.setOptionIdKey(identry)
        },
        displayOptions: (newState) => (state) => ({
            optDisplayed: newState,
        }),
        setOptionIdKey: (newState) => (state) => ({
            optIdEntry: newState,
        }),
        setConfirmAction: (newState) => (state) => ({
            confirmAction: newState,
        }),
    },
    (state, actions) => (props, children) => (
        <div key={props.key}>
            <div class="sup-tab-header">
                {props.document.tabs.value.map((tab) => (
                    <div
                        class={
                            'sup-tab sup-' +
                            tab.key +
                            (state.active === tab.key ? ' active' : '')
                        }
                        onclick={() => {
                            actions.setActiveBlock(tab)
                        }}
                    >
                        <p class="">{tab.label}</p>
                    </div>
                ))}
            </div>
            <div class="sup-tab-blocks">
                {props.document.tabs.value.map((tab) => (
                    <div
                        key={'tab-' + tab.key}
                        class={
                            'sup-tab sup-' +
                            tab.label +
                            (state.active === tab.key ? ' active' : '')
                        }
                    >
                        {state.values
                            .filter((f) => f.key === tab.key)
                            .map((val) => (
                                <div class="col-12">
                                    {[
                                        'accountkpi',
                                        'programsessions',
                                        'programsessionsetp',
                                        'programsteps',
                                        'alerts&seuiles',
                                    ].indexOf(val.key) > -1 && (
                                        <div
                                            key={'tab-left-' + val.key}
                                            class={'col-2 tab-left'}
                                            style={{
                                                'vertical-align': 'top',
                                                'padding': '0px',
                                            }}
                                        >
                                            {val.key === 'accountkpi' && (
                                                <div>
                                                    <div class="">
                                                        {t('Kpi du profil')}
                                                    </div>
                                                    <Link
                                                        target={'_blank'}
                                                        class={'btn btn-link'}
                                                        to={`${process.env.APP_API_URL}/admin/users/${state.originaldocument.id}/download/profile/?xtoken=${state.token}`}
                                                        class=""
                                                    >
                                                        {t('Télécharger')}
                                                    </Link>
                                                </div>
                                            )}
                                            {val.key.indexOf(
                                                'programsessions'
                                            ) > -1 && (
                                                <div>
                                                    <p
                                                        class={'btn btn-link'}
                                                        onclick={() => {
                                                            actions.sendAndRedirect(
                                                                {
                                                                    props: props,
                                                                    from: state.template,
                                                                    to: 'newsession',
                                                                }
                                                            )
                                                        }}
                                                    >
                                                        {t('Ajouter')}
                                                    </p>
                                                </div>
                                            )}
                                            {val.key === 'programsteps' && (
                                                <div>
                                                    <p
                                                        class={'btn btn-link'}
                                                        onclick={() => {
                                                            actions.sendAndRedirect(
                                                                {
                                                                    props: props,
                                                                    from: state.template,
                                                                    to: 'newstep',
                                                                }
                                                            )
                                                        }}
                                                    >
                                                        {t('Ajouter')}
                                                    </p>
                                                </div>
                                            )}
                                            {val.key === 'alerts&seuiles' && (
                                                <div>
                                                    <p
                                                        class={'btn btn-link'}
                                                        onclick={() => {
                                                            actions.sendAndRedirect(
                                                                {
                                                                    props: props,
                                                                    from: 'customer',
                                                                    to: 'scenario',
                                                                }
                                                            )
                                                        }}
                                                    >
                                                        {t('Ajouter')}
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    <div
                                        class={
                                            [
                                                'accountkpi',
                                                'programsessions',
                                                'programsteps',
                                                'alerts&seuiles',
                                                'programsessionsetp',
                                            ].indexOf(val.key) > -1
                                                ? 'col-10'
                                                : 'col-12'
                                        }
                                    >
                                        {val.key === 'connexionhistoric' && (
                                            <div key={'cnxs-head-' + val.key}>
                                                <div class="cnxs-table-head">
                                                    <div class="cnxs-table-head-entry">
                                                        {t('Action')}
                                                    </div>
                                                    <div class="cnxs-table-head-entry">
                                                        {t('Date')}
                                                    </div>
                                                    <div class="cnxs-table-head-entry">
                                                        {t('Réf paiement')}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {val.key === 'accountlicenses' && (
                                            <div key={'lcss-head-' + val.key}>
                                                <div class="lcss-table-head">
                                                    <div class="lcss-table-head-entry">
                                                        {t('Status')}
                                                    </div>
                                                    <div class="lcss-table-head-entry">
                                                        {t('Quantité')}
                                                    </div>
                                                    <div class="lcss-table-head-entry">
                                                        {t('Wording')}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {val.key === 'accountnurseteam' && (
                                            <div key={'lcss-head-' + val.key}>
                                                <div class="lcss-table-head">
                                                    <div class="lcss-table-head-entry">
                                                        {t('Equipe')}
                                                    </div>
                                                    <div class="lcss-table-head-entry">
                                                        {t('Effectif')}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {val.key === 'programsessions' && (
                                            <div key={'lcss-head-' + val.key}>
                                                <div class="lcss-table-head">
                                                    <div class="lcss-table-head-entry">
                                                        {t('ID')}
                                                    </div>
                                                    <div class="lcss-table-head-entry">
                                                        {t('#Session')}
                                                    </div>
                                                    <div class="lcss-table-head-entry">
                                                        {t('Intervalle')}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {val.key === 'programsessionsetp' && (
                                            <div key={'lcss-head-' + val.key}>
                                                <div class="lcss-table-head">
                                                    <div class="lcss-table-head-entry">
                                                        {t('ID')}
                                                    </div>
                                                    <div class="lcss-table-head-entry">
                                                        {t('#Session')}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {val.key === 'programsteps' && (
                                            <div key={'lcss-head-' + val.key}>
                                                <div class="lcss-table-head">
                                                    <div class="lcss-table-head-entry">
                                                        {t('ID')}
                                                    </div>
                                                    <div class="lcss-table-head-entry">
                                                        {t('Position')}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {val.key === 'alerts&seuiles' && (
                                            <div key={'lcss-head-' + val.key}>
                                                <div class="lcss-table-head">
                                                    <div class="lcss-table-head-entry">
                                                        {t('Nom')}
                                                    </div>
                                                    <div class="lcss-table-head-entry">
                                                        {t('Création')}
                                                    </div>
                                                    <div class="lcss-table-head-entry">
                                                        {t('Dernière édition')}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {val.key === 'forumhistoric' && (
                                            <div key={'frhc-head-' + val.key}>
                                                <div class="frhc-table-head">
                                                    <div class="frhc-table-head-entry">
                                                        {t('Post')}
                                                    </div>
                                                    <div class="frhc-table-head-entry">
                                                        {t('Date')}
                                                    </div>
                                                    <div class="frhc-table-head-entry">
                                                        {t('Signal') +
                                                            ' (' +
                                                            (isDefined(
                                                                state.forumTopic
                                                            ) &&
                                                            isDefined(
                                                                state.forumTopic
                                                                    .list
                                                            )
                                                                ? state.forumTopic.list.filter(
                                                                      (f) =>
                                                                          f.abusesCount >
                                                                          0
                                                                  ).length
                                                                : '0') +
                                                            ')'}
                                                    </div>
                                                    <div class="frhc-table-head-entry">
                                                        {t('Modération') +
                                                            ' (' +
                                                            (isDefined(
                                                                state.forumTopic
                                                            ) &&
                                                            isDefined(
                                                                state.forumTopic
                                                                    .list
                                                            )
                                                                ? state.forumTopic.list.filter(
                                                                      (f) =>
                                                                          f.moderated >
                                                                          0
                                                                  ).length
                                                                : '0') +
                                                            ')'}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {val.key === 'commenthistoric' && (
                                            <div key={'cthc-head-' + val.key}>
                                                <div class="cthc-table-head">
                                                    <div class="cthc-table-head-entry">
                                                        {t('Post')}
                                                    </div>
                                                    <div class="cthc-table-head-entry">
                                                        {t('Date')}
                                                    </div>
                                                    <div class="cthc-table-head-entry">
                                                        {t('Signal') +
                                                            ' (' +
                                                            (isDefined(
                                                                state.forumTopicAnswers
                                                            ) &&
                                                            isDefined(
                                                                state
                                                                    .forumTopicAnswers
                                                                    .list
                                                            )
                                                                ? state.forumTopicAnswers.list.filter(
                                                                      (f) =>
                                                                          f.abusesCount >
                                                                          0
                                                                  ).length
                                                                : '0') +
                                                            ')'}
                                                    </div>
                                                    <div class="cthc-table-head-entry">
                                                        {t('Modération') +
                                                            ' (' +
                                                            (isDefined(
                                                                state.forumTopicAnswers
                                                            ) &&
                                                            isDefined(
                                                                state
                                                                    .forumTopicAnswers
                                                                    .list
                                                            )
                                                                ? state.forumTopicAnswers.list.filter(
                                                                      (f) =>
                                                                          f.moderated >
                                                                          0
                                                                  ).length
                                                                : '0') +
                                                            ')'}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <div
                                            key={'tab-right-' + val.key}
                                            class={
                                                val.key +
                                                ' tab-right sup-group-form'
                                            }
                                            style={{
                                                'vertical-align': 'top',
                                                'float': 'right',
                                            }}
                                        >
                                            {state.parsedKpis !== undefined &&
                                                val.key === 'accountkpi' && (
                                                    <div
                                                        key={'kpis-' + val.key}
                                                    >
                                                        {state.parsedKpis.map(
                                                            (kpip) => (
                                                                <p
                                                                    key={
                                                                        'kpi-' +
                                                                        kpip.label +
                                                                        '-' +
                                                                        kpip.value
                                                                    }
                                                                    class=""
                                                                >
                                                                    {kpip.label +
                                                                        ' == ' +
                                                                        kpip.value}
                                                                </p>
                                                            )
                                                        )}
                                                    </div>
                                                )}
                                            {state.parsedConnexions !==
                                                undefined &&
                                                val.key ===
                                                    'connexionhistoric' && (
                                                    <div
                                                        key={'cnxs-' + val.key}
                                                    >
                                                        {state.parsedConnexions.map(
                                                            (cnx) => (
                                                                <p
                                                                    key={
                                                                        'cnx-' +
                                                                        val.key
                                                                    }
                                                                    class="cnxs-table-content-entry"
                                                                >
                                                                    <font>
                                                                        {
                                                                            cnx.actions
                                                                        }
                                                                    </font>
                                                                    <font>
                                                                        {getYMDfromDateObj(
                                                                            new Date(
                                                                                cnx.date.date.replace(
                                                                                    ' ',
                                                                                    'T'
                                                                                ) +
                                                                                    'Z'
                                                                            ),
                                                                            true
                                                                        )}
                                                                    </font>
                                                                    <font>
                                                                        {
                                                                            cnx.ref
                                                                        }
                                                                    </font>
                                                                </p>
                                                            )
                                                        )}
                                                    </div>
                                                )}
                                            {val.key === 'accountlicenses' && (
                                                <div key={'lcss-' + val.key}>
                                                    <p
                                                        key={
                                                            'lcs-active-' +
                                                            val.key
                                                        }
                                                        class="lcss-table-content-entry"
                                                    >
                                                        <font>
                                                            {t('Actives')}
                                                        </font>
                                                        <font>
                                                            {
                                                                props
                                                                    .originaldocument
                                                                    .activeLicense
                                                            }
                                                        </font>
                                                        <font>
                                                            {t(
                                                                'Utilisateurs inscrits'
                                                            )}
                                                        </font>
                                                    </p>
                                                    <p
                                                        key={
                                                            'lcs-suspended-' +
                                                            val.key
                                                        }
                                                        class="lcss-table-content-entry"
                                                    >
                                                        <font>
                                                            {t('Suspendues')}
                                                        </font>
                                                        <font>
                                                            {
                                                                props
                                                                    .originaldocument
                                                                    .suspendedLicense
                                                            }
                                                        </font>
                                                        <font>
                                                            {t(
                                                                'Date d‘expiration dépassée'
                                                            )}
                                                        </font>
                                                    </p>
                                                    <p
                                                        key={
                                                            'lcs-canceled-' +
                                                            val.key
                                                        }
                                                        class="lcss-table-content-entry"
                                                    >
                                                        <font>
                                                            {t('Annulées')}
                                                        </font>
                                                        <font>
                                                            {
                                                                props
                                                                    .originaldocument
                                                                    .canceledLicense
                                                            }
                                                        </font>
                                                        <font>
                                                            {t(
                                                                'Licences résiliées'
                                                            )}
                                                        </font>
                                                    </p>
                                                </div>
                                            )}
                                            {val.key === 'accountnurseteam' &&
                                                isDefined(
                                                    props.originaldocument
                                                        .userTheraflow
                                                ) && (
                                                    <div
                                                        key={'lcss-' + val.key}
                                                    >
                                                        <p
                                                            key={
                                                                'lcs-doctor-' +
                                                                val.key
                                                            }
                                                            class="lcss-table-content-entry"
                                                        >
                                                            <font>
                                                                {t(
                                                                    'Médecin télésurveillant'
                                                                )}
                                                            </font>
                                                            <font>
                                                                {isDefined(
                                                                    props
                                                                        .originaldocument
                                                                        .userTheraflow
                                                                        .doctor
                                                                )
                                                                    ? '1'
                                                                    : '0'}
                                                            </font>
                                                        </p>
                                                        <p
                                                            key={
                                                                'lcs-therapeut-' +
                                                                val.key
                                                            }
                                                            class="lcss-table-content-entry"
                                                        >
                                                            <font>
                                                                {t(
                                                                    'Accompagnant thérapeutique'
                                                                )}
                                                            </font>
                                                            <font>
                                                                {isDefined(
                                                                    props
                                                                        .originaldocument
                                                                        .userTheraflow
                                                                        .coach
                                                                )
                                                                    ? '1'
                                                                    : '0'}
                                                            </font>
                                                        </p>
                                                        <p
                                                            key={
                                                                'lcs-nurses-' +
                                                                val.key
                                                            }
                                                            class="lcss-table-content-entry"
                                                        >
                                                            <font>
                                                                {t(
                                                                    'Equipe médicale'
                                                                )}
                                                            </font>
                                                            <font>
                                                                {isDefined(
                                                                    props
                                                                        .originaldocument
                                                                        .userTheraflow
                                                                        .nurses
                                                                )
                                                                    ? props
                                                                          .originaldocument
                                                                          .userTheraflow
                                                                          .nurses
                                                                          .length
                                                                    : '0'}
                                                            </font>
                                                        </p>
                                                    </div>
                                                )}
                                            {state.originaldocument !== null &&
                                                state.originaldocument
                                                    .sessions !== undefined &&
                                                val.key ===
                                                    'programsessions' && (
                                                    <div
                                                        class="sup-drop-down-programsessions-wrapper"
                                                        key={'cnxs-' + val.key}
                                                        onblur={() => {
                                                            actions.toggleOptions(
                                                                false
                                                            )
                                                        }}
                                                    >
                                                        {state.originaldocument.sessions.map(
                                                            (sess) => (
                                                                <div
                                                                    key={
                                                                        'lcss-' +
                                                                        sess.id
                                                                    }
                                                                    style={{
                                                                        position:
                                                                            'relative',
                                                                    }}
                                                                    ondblclick={() => {
                                                                        actions.sendAndRedirect(
                                                                            {
                                                                                props: props,
                                                                                from: state.template,
                                                                                to: 'session',
                                                                                refId: props
                                                                                    .document
                                                                                    .id,
                                                                                refSId: sess.id,
                                                                            }
                                                                        )
                                                                    }}
                                                                >
                                                                    <p
                                                                        key={
                                                                            'lcs-active-' +
                                                                            sess.id
                                                                        }
                                                                        class="lcss-table-content-entry"
                                                                    >
                                                                        <font>
                                                                            {
                                                                                sess.name
                                                                            }
                                                                        </font>
                                                                        <font>
                                                                            {
                                                                                sess.interval
                                                                            }
                                                                        </font>
                                                                        <font>
                                                                            {sess.interval ===
                                                                            -1
                                                                                ? t(
                                                                                      'Start'
                                                                                  )
                                                                                : t(
                                                                                      'J+'
                                                                                  ) +
                                                                                  sess.interval}
                                                                        </font>
                                                                    </p>
                                                                    <div class="sup-drop-down-tab-wrapper">
                                                                        <div
                                                                            class="sup-drop-down"
                                                                            onclick={() =>
                                                                                actions.toggleOptions(
                                                                                    sess.id
                                                                                )
                                                                            }
                                                                        ></div>
                                                                        {state.optDisplayed &&
                                                                            state.optIdEntry ===
                                                                                sess.id && (
                                                                                <div class="sup-drop-down-options">
                                                                                    <p
                                                                                        class={
                                                                                            'sup-drop-down-option'
                                                                                        }
                                                                                        onclick={() => {
                                                                                            actions.sendAndRedirect(
                                                                                                {
                                                                                                    props: props,
                                                                                                    from: state.template,
                                                                                                    to: 'session',
                                                                                                    refId: props
                                                                                                        .document
                                                                                                        .id,
                                                                                                    refSId: sess.id,
                                                                                                }
                                                                                            )
                                                                                        }}
                                                                                    >
                                                                                        {t(
                                                                                            'Éditer'
                                                                                        )}
                                                                                    </p>
                                                                                    <div
                                                                                        class={
                                                                                            'sup-drop-down-option sup-drop-down-option-dangerous'
                                                                                        }
                                                                                        onclick={() => {
                                                                                            if (
                                                                                                !state.confirmAction
                                                                                            ) {
                                                                                                actions.setConfirmAction(
                                                                                                    true
                                                                                                )
                                                                                            } else {
                                                                                                actions.setConfirmAction(
                                                                                                    false
                                                                                                )
                                                                                                actions.toggleOptions(
                                                                                                    false
                                                                                                )
                                                                                                actions.deleteEntryAndUpdate(
                                                                                                    {
                                                                                                        props: props,
                                                                                                        from: state.template,
                                                                                                        to: 'session',
                                                                                                        refId: props
                                                                                                            .document
                                                                                                            .id,
                                                                                                        refSId: sess.id,
                                                                                                    }
                                                                                                )
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        {state.confirmAction
                                                                                            ? t(
                                                                                                  'Confirmer ?'
                                                                                              )
                                                                                            : t(
                                                                                                  'Supprimer'
                                                                                              )}
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                    </div>
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                )}
                                            {state.originaldocument !== null &&
                                                state.originaldocument
                                                    .sessions !== undefined &&
                                                val.key ===
                                                    'programsessionsetp' && (
                                                    <div
                                                        class="sup-drop-down-programsessionsetp-wrapper"
                                                        key={'cnxs-' + val.key}
                                                        onblur={() => {
                                                            actions.toggleOptions(
                                                                false
                                                            )
                                                        }}
                                                    >
                                                        {state.originaldocument.sessions.map(
                                                            (sess) => (
                                                                <div
                                                                    key={
                                                                        'lcss-' +
                                                                        sess.id
                                                                    }
                                                                    style={{
                                                                        position:
                                                                            'relative',
                                                                    }}
                                                                    ondblclick={() => {
                                                                        actions.sendAndRedirect(
                                                                            {
                                                                                props: props,
                                                                                from: state.template,
                                                                                to: 'session',
                                                                                refId: props
                                                                                    .document
                                                                                    .id,
                                                                                refSId: sess.id,
                                                                            }
                                                                        )
                                                                    }}
                                                                >
                                                                    <p
                                                                        key={
                                                                            'lcs-active-' +
                                                                            sess.id
                                                                        }
                                                                        class="lcss-table-content-entry"
                                                                    >
                                                                        <font>
                                                                            {
                                                                                sess.name
                                                                            }
                                                                        </font>
                                                                        <font>
                                                                            {
                                                                                sess.sessionNumber
                                                                            }
                                                                        </font>
                                                                    </p>
                                                                    <div class="sup-drop-down-tab-wrapper">
                                                                        <div
                                                                            class="sup-drop-down"
                                                                            onclick={() =>
                                                                                actions.toggleOptions(
                                                                                    sess.id
                                                                                )
                                                                            }
                                                                        ></div>
                                                                        {state.optDisplayed &&
                                                                            state.optIdEntry ===
                                                                                sess.id && (
                                                                                <div class="sup-drop-down-options">
                                                                                    <p
                                                                                        class={
                                                                                            'sup-drop-down-option'
                                                                                        }
                                                                                        onclick={() => {
                                                                                            actions.sendAndRedirect(
                                                                                                {
                                                                                                    props: props,
                                                                                                    from: state.template,
                                                                                                    to: 'session',
                                                                                                    refId: props
                                                                                                        .document
                                                                                                        .id,
                                                                                                    refSId: sess.id,
                                                                                                }
                                                                                            )
                                                                                        }}
                                                                                    >
                                                                                        {t(
                                                                                            'Éditer'
                                                                                        )}
                                                                                    </p>
                                                                                    <div
                                                                                        class={
                                                                                            'sup-drop-down-option sup-drop-down-option-dangerous'
                                                                                        }
                                                                                        onclick={() => {
                                                                                            if (
                                                                                                !state.confirmAction
                                                                                            ) {
                                                                                                actions.setConfirmAction(
                                                                                                    true
                                                                                                )
                                                                                            } else {
                                                                                                actions.setConfirmAction(
                                                                                                    false
                                                                                                )
                                                                                                actions.toggleOptions(
                                                                                                    false
                                                                                                )
                                                                                                actions.deleteEntryAndUpdate(
                                                                                                    {
                                                                                                        props: props,
                                                                                                        from: state.template,
                                                                                                        to: 'session',
                                                                                                        refId: props
                                                                                                            .document
                                                                                                            .id,
                                                                                                        refSId: sess.id,
                                                                                                    }
                                                                                                )
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        {state.confirmAction
                                                                                            ? t(
                                                                                                  'Confirmer ?'
                                                                                              )
                                                                                            : t(
                                                                                                  'Supprimer'
                                                                                              )}
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                    </div>
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                )}
                                            {state.originaldocument !== null &&
                                                state.originaldocument.steps !==
                                                    undefined &&
                                                val.key === 'programsteps' && (
                                                    <div
                                                        class="sup-drop-down-programsteps-wrapper"
                                                        key={'cnxs-' + val.key}
                                                        onblur={() => {
                                                            actions.toggleOptions(
                                                                false
                                                            )
                                                        }}
                                                    >
                                                        {state.originaldocument.steps.map(
                                                            (step) => (
                                                                <div
                                                                    key={
                                                                        'lcss-' +
                                                                        step.id
                                                                    }
                                                                    style={{
                                                                        position:
                                                                            'relative',
                                                                    }}
                                                                    ondblclick={() => {
                                                                        actions.sendAndRedirect(
                                                                            {
                                                                                props: props,
                                                                                from: state.template,
                                                                                to: 'step',
                                                                                refId: null,
                                                                                refSId: null,
                                                                                refSSId:
                                                                                    step.id,
                                                                                refType:
                                                                                    step.type ||
                                                                                    1,
                                                                            }
                                                                        )
                                                                    }}
                                                                >
                                                                    <p
                                                                        key={
                                                                            'lcs-active-' +
                                                                            step.id
                                                                        }
                                                                        class="lcss-table-content-entry"
                                                                    >
                                                                        <font>
                                                                            {
                                                                                step.name
                                                                            }
                                                                        </font>
                                                                        <font>
                                                                            {
                                                                                step.order
                                                                            }
                                                                        </font>
                                                                    </p>
                                                                    <div class="sup-drop-down-tab-wrapper">
                                                                        <div
                                                                            class="sup-drop-down"
                                                                            onclick={() =>
                                                                                actions.toggleOptions(
                                                                                    step.id
                                                                                )
                                                                            }
                                                                        ></div>
                                                                        {state.optDisplayed &&
                                                                            state.optIdEntry ===
                                                                                step.id && (
                                                                                <div class="sup-drop-down-options">
                                                                                    <p
                                                                                        class={
                                                                                            'sup-drop-down-option'
                                                                                        }
                                                                                        onclick={() => {
                                                                                            actions.sendAndRedirect(
                                                                                                {
                                                                                                    props: props,
                                                                                                    from: state.template,
                                                                                                    to: 'step',
                                                                                                    refId: null,
                                                                                                    refSId: null,
                                                                                                    refSSId:
                                                                                                        step.id,
                                                                                                    refType:
                                                                                                        step.type ||
                                                                                                        1,
                                                                                                }
                                                                                            )
                                                                                        }}
                                                                                    >
                                                                                        {t(
                                                                                            'Éditer'
                                                                                        )}
                                                                                    </p>
                                                                                    <div
                                                                                        class={
                                                                                            'sup-drop-down-option sup-drop-down-option-dangerous'
                                                                                        }
                                                                                        onclick={() => {
                                                                                            if (
                                                                                                !state.confirmAction
                                                                                            ) {
                                                                                                actions.setConfirmAction(
                                                                                                    true
                                                                                                )
                                                                                            } else {
                                                                                                actions.setConfirmAction(
                                                                                                    false
                                                                                                )
                                                                                                actions.toggleOptions(
                                                                                                    false
                                                                                                )
                                                                                                actions.deleteEntryAndUpdate(
                                                                                                    {
                                                                                                        props: props,
                                                                                                        from: state.template,
                                                                                                        to: 'step',
                                                                                                        refId: null,
                                                                                                        refSId: null,
                                                                                                        refSSId:
                                                                                                            step.id,
                                                                                                    }
                                                                                                )
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        {state.confirmAction
                                                                                            ? t(
                                                                                                  'Confirmer ?'
                                                                                              )
                                                                                            : t(
                                                                                                  'Supprimer'
                                                                                              )}
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                    </div>
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                )}
                                            {val.key === 'forumhistoric' && (
                                                <div>
                                                    <div
                                                        key={
                                                            'frhc-head-' +
                                                            val.key
                                                        }
                                                    >
                                                        {isDefined(
                                                            state.forumTopic
                                                        ) &&
                                                            isDefined(
                                                                state.forumTopic
                                                                    .list
                                                            ) && (
                                                                <div
                                                                    class="frhc-table-head"
                                                                    onblur={() => {
                                                                        actions.toggleOptions(
                                                                            false
                                                                        )
                                                                    }}
                                                                >
                                                                    {state.forumTopic.list.map(
                                                                        (
                                                                            top
                                                                        ) => (
                                                                            <div
                                                                                key={
                                                                                    'frhc-' +
                                                                                    top.id
                                                                                }
                                                                                class={
                                                                                    top.moderated >
                                                                                        0 ||
                                                                                    top.abusesCount >
                                                                                        0
                                                                                        ? ' red-colored'
                                                                                        : ''
                                                                                }
                                                                                style={{
                                                                                    position:
                                                                                        'relative',
                                                                                }}
                                                                                ondblclick={() => {
                                                                                    window.location.href =
                                                                                        addCSRFToken(
                                                                                            allRoutes[
                                                                                                'private.forum.forumfr.edit'
                                                                                            ].pathname.replace(
                                                                                                ':id',
                                                                                                top.id
                                                                                            )
                                                                                        )
                                                                                }}
                                                                            >
                                                                                <p
                                                                                    key={
                                                                                        'frhc-' +
                                                                                        val.key +
                                                                                        top.id
                                                                                    }
                                                                                    class={
                                                                                        'frhc-table-content-entry'
                                                                                    }
                                                                                >
                                                                                    <font>
                                                                                        {
                                                                                            top.content
                                                                                        }
                                                                                    </font>
                                                                                    <font>
                                                                                        {top.date !==
                                                                                        undefined
                                                                                            ? getYMDfromDateObj(
                                                                                                  new Date(
                                                                                                      top.date.date
                                                                                                  ),
                                                                                                  true
                                                                                              )
                                                                                            : '-'}
                                                                                    </font>
                                                                                    <font>
                                                                                        {
                                                                                            top.abusesCount
                                                                                        }
                                                                                    </font>
                                                                                    <font>
                                                                                        {
                                                                                            top.moderated
                                                                                        }
                                                                                    </font>
                                                                                </p>
                                                                                <div class="sup-drop-down-tab-wrapper">
                                                                                    <div
                                                                                        class="sup-drop-down"
                                                                                        onclick={() =>
                                                                                            actions.toggleOptions(
                                                                                                top.id
                                                                                            )
                                                                                        }
                                                                                    ></div>
                                                                                    {state.optDisplayed &&
                                                                                        state.optIdEntry ===
                                                                                            top.id && (
                                                                                            <div class="sup-drop-down-options">
                                                                                                <p
                                                                                                    class={
                                                                                                        'sup-drop-down-option'
                                                                                                    }
                                                                                                    onclick={() => {
                                                                                                        window.location.href =
                                                                                                            addCSRFToken(
                                                                                                                allRoutes[
                                                                                                                    'private.forum.forumfr.edit'
                                                                                                                ].pathname.replace(
                                                                                                                    ':id',
                                                                                                                    top.id
                                                                                                                )
                                                                                                            )
                                                                                                    }}
                                                                                                >
                                                                                                    {t(
                                                                                                        'Éditer'
                                                                                                    )}
                                                                                                </p>
                                                                                            </div>
                                                                                        )}
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </div>
                                                            )}
                                                    </div>
                                                    <div class="customtable-page">
                                                        <p class="">
                                                            {'Page' +
                                                                ' #' +
                                                                (state.pageTopic +
                                                                    1)}
                                                        </p>
                                                    </div>
                                                    {((isDefined(
                                                        state.forumTopic
                                                    ) &&
                                                        isDefined(
                                                            state.forumTopic
                                                                .list
                                                        ) &&
                                                        state.forumTopic.list
                                                            .length ===
                                                            state.pagination) ||
                                                        state.pageTopic >
                                                            0) && (
                                                        <div class="customtable-pagination">
                                                            {state.pageTopic >
                                                                0 && (
                                                                <div
                                                                    class="customtable-pagination-side"
                                                                    onclick={() => {
                                                                        actions.setPageTopic(
                                                                            state.pageTopic -
                                                                                1
                                                                        )
                                                                        actions.updateTable(
                                                                            'listing-forum'
                                                                        )
                                                                    }}
                                                                >
                                                                    {'<'}
                                                                </div>
                                                            )}
                                                            {state.forumTopic
                                                                .list.length ===
                                                                state.pagination && (
                                                                <div
                                                                    class="customtable-pagination-side"
                                                                    onclick={() => {
                                                                        actions.setPageTopic(
                                                                            state.pageTopic +
                                                                                1
                                                                        )
                                                                        actions.updateTable(
                                                                            'listing-forum'
                                                                        )
                                                                    }}
                                                                >
                                                                    {'>'}
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                            {val.key === 'commenthistoric' && (
                                                <div>
                                                    <div
                                                        key={
                                                            'cthc-head-' +
                                                            val.key
                                                        }
                                                    >
                                                        {isDefined(
                                                            state.forumTopicAnswers
                                                        ) &&
                                                            isDefined(
                                                                state
                                                                    .forumTopicAnswers
                                                                    .list
                                                            ) && (
                                                                <div
                                                                    class="cthc-table-head"
                                                                    onblur={() => {
                                                                        actions.toggleOptions(
                                                                            false
                                                                        )
                                                                    }}
                                                                >
                                                                    {state.forumTopicAnswers.list.map(
                                                                        (
                                                                            ans
                                                                        ) => (
                                                                            <div
                                                                                key={
                                                                                    'cthc-' +
                                                                                    ans.id
                                                                                }
                                                                                class={
                                                                                    ans.moderated >
                                                                                        0 ||
                                                                                    ans.abusesCount >
                                                                                        0
                                                                                        ? ' red-colored'
                                                                                        : ''
                                                                                }
                                                                                style={{
                                                                                    position:
                                                                                        'relative',
                                                                                }}
                                                                                ondblclick={() => {
                                                                                    window.location.href =
                                                                                        addCSRFToken(
                                                                                            allRoutes[
                                                                                                'private.forum.forumfr.edit'
                                                                                            ].pathname.replace(
                                                                                                ':id',
                                                                                                ans.topicId
                                                                                            ) +
                                                                                                '?id=' +
                                                                                                ans.id
                                                                                        )
                                                                                }}
                                                                            >
                                                                                <p
                                                                                    key={
                                                                                        'cthc-' +
                                                                                        val.key +
                                                                                        ans.id
                                                                                    }
                                                                                    class={
                                                                                        'cthc-table-content-entry'
                                                                                    }
                                                                                >
                                                                                    <font>
                                                                                        {
                                                                                            ans.content
                                                                                        }
                                                                                    </font>
                                                                                    <font>
                                                                                        {ans.date !==
                                                                                        undefined
                                                                                            ? getYMDfromDateObj(
                                                                                                  new Date(
                                                                                                      ans.date.date
                                                                                                  ),
                                                                                                  true
                                                                                              )
                                                                                            : '-'}
                                                                                    </font>
                                                                                    <font>
                                                                                        {
                                                                                            ans.abusesCount
                                                                                        }
                                                                                    </font>
                                                                                    <font>
                                                                                        {
                                                                                            ans.moderated
                                                                                        }
                                                                                    </font>
                                                                                </p>
                                                                                <div class="sup-drop-down-tab-wrapper">
                                                                                    <div
                                                                                        class="sup-drop-down"
                                                                                        onclick={() =>
                                                                                            actions.toggleOptions(
                                                                                                ans.id
                                                                                            )
                                                                                        }
                                                                                    ></div>
                                                                                    {state.optDisplayed &&
                                                                                        state.optIdEntry ===
                                                                                            ans.id && (
                                                                                            <div class="sup-drop-down-options">
                                                                                                <p
                                                                                                    class={
                                                                                                        'sup-drop-down-option'
                                                                                                    }
                                                                                                    onclick={() => {
                                                                                                        window.location.href =
                                                                                                            addCSRFToken(
                                                                                                                allRoutes[
                                                                                                                    'private.forum.forumfr.edit'
                                                                                                                ].pathname.replace(
                                                                                                                    ':id',
                                                                                                                    ans.topicId
                                                                                                                ) +
                                                                                                                    '?id=' +
                                                                                                                    ans.id
                                                                                                            )
                                                                                                    }}
                                                                                                >
                                                                                                    {t(
                                                                                                        'Éditer'
                                                                                                    )}
                                                                                                </p>
                                                                                            </div>
                                                                                        )}
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </div>
                                                            )}
                                                    </div>
                                                    <div class="customtable-page">
                                                        <p class="">
                                                            {'Page' +
                                                                ' #' +
                                                                (state.pageTopicAnswer +
                                                                    1)}
                                                        </p>
                                                    </div>
                                                    {((isDefined(
                                                        state.forumTopicAnswers
                                                    ) &&
                                                        isDefined(
                                                            state
                                                                .forumTopicAnswers
                                                                .list
                                                        ) &&
                                                        state.forumTopicAnswers
                                                            .list.length ===
                                                            state.pagination) ||
                                                        state.pageTopicAnswer >
                                                            0) && (
                                                        <div class="customtable-pagination">
                                                            {state.pageTopicAnswer >
                                                                0 && (
                                                                <div
                                                                    class="customtable-pagination-side"
                                                                    onclick={() => {
                                                                        actions.setPageTopicAnswer(
                                                                            state.pageTopicAnswer -
                                                                                1
                                                                        )
                                                                        actions.updateTable(
                                                                            'listing-comment'
                                                                        )
                                                                    }}
                                                                >
                                                                    {'<'}
                                                                </div>
                                                            )}
                                                            {state
                                                                .forumTopicAnswers
                                                                .list.length ===
                                                                state.pagination && (
                                                                <div
                                                                    class="customtable-pagination-side"
                                                                    onclick={() => {
                                                                        actions.setPageTopicAnswer(
                                                                            state.pageTopicAnswer +
                                                                                1
                                                                        )
                                                                        actions.updateTable(
                                                                            'listing-comment'
                                                                        )
                                                                    }}
                                                                >
                                                                    {'>'}
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                ))}
            </div>
        </div>
    ),
    'adminformblockastab'
)

export { AdminFormBlockAsTab }

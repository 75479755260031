import { settings } from '@app/core/settings'
import { handleErrors } from '@app/api/errors'
import { queueQueries } from '@app/api/controlleur'

const { API_URL } = settings

function getProgramById(id) {
    return queueQueries(`${API_URL}/admin/programs/${id}/`, 'GET')
}

function getProgramSessionById(id, sid) {
    return queueQueries(
        `${API_URL}/admin/programs/${id}/session/${sid}/`,
        'GET'
    )
}
function deleteProgramSessionById(id, sid) {
    return queueQueries(
        `${API_URL}/admin/programs/${id}/session/${sid}/`,
        'DELETE'
    )
}
function addProgramSession(id, data) {
    return queueQueries(
        `${API_URL}/admin/programs/${id}/session/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function editProgramSession(id, sid, data) {
    return queueQueries(
        `${API_URL}/admin/programs/${id}/session/${sid}/`,
        'PUT',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}

function getProgramSessionStepById(id, sid, ssid) {
    return queueQueries(
        `${API_URL}/admin/programs/${id}/session/${sid}/step/${ssid}/`,
        'GET'
    )
}
function addProgramSessionStep(id, sid, data) {
    return queueQueries(
        `${API_URL}/admin/programs/${id}/session/${sid}/step/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function editProgramSessionStep(id, sid, ssid, data) {
    return queueQueries(
        `${API_URL}/admin/programs/${id}/session/${sid}/step/${ssid}/`,
        'PUT',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function deleteProgramSessionStepById(id, sid, ssid) {
    return queueQueries(
        `${API_URL}/admin/programs/${id}/session/${sid}/step/${ssid}/`,
        'DELETE'
    )
}

export const Programs = {
    getProgramById,
    getProgramSessionById,
    addProgramSession,
    deleteProgramSessionById,
    editProgramSession,
    getProgramSessionStepById,
    addProgramSessionStep,
    editProgramSessionStep,
    deleteProgramSessionStepById,
}

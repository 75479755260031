import { h } from '@app/utils'
import { Avatar } from '.'

describe('Avatar element', () => {
    it('should return a well structured vNode', () => {
        expect(<Avatar />).toEqual(
            <figure class="avatar avatar-xl centered">
                <img src="" alt="" />
            </figure>
        )
    })
})

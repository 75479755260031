import { settings } from '@app/core/settings'
import { handleErrors } from '@app/api/errors'
import { queueQueries } from '@app/api/controlleur'

const { API_URL } = settings

// async function checkInvitationCode(inviteCode) {
//     return queueQueries(`${API_URL}/invitation-code/${inviteCode}/`, 'GET', 'application/json', true, true, true, false);
// }

// function setUserSettings(settings) {
//     return queueQueries(`${API_URL}/settings/`, 'POST', 'application/json', true, true, true, true, true, settings);
// }

// function setUserSponsor(sponsor) {
//     return queueQueries(`${API_URL}/user/sponsor/`, 'POST', 'application/json', true, true, true, true, true, sponsor);
// }

// function downloadRGPDDatas() {
//     return queueQueries(`${API_URL}/export/datas/`, 'GET');
// }

// /**
//  * Calls API to create B2C user account
//  * @see https://betterise-api-doc.betterise.me/#api-User-createUser
//  * @param {String} accountType Can be "b2c", "company", or "mutual"
//  * @param {String} accountEmail Account email
//  * @param {String} accountPassword Account password
//  * @return Response object
//  */
// async function createUser(accountType, fields) {
//     return queueQueries(`${API_URL}/user/register/${accountType}/`, 'POST', 'application/json', true, true, true, false, true, fields);
// }

function getCardsByType(search, type) {
    return queueQueries(
        `${API_URL}/admin/cards/autocomplete/${search}/?type=${type}`,
        'GET'
    )
}
function getArticles(search) {
    return queueQueries(
        `${API_URL}/admin/articles/autocomplete/${search}/`,
        'GET'
    )
}
function getRecipes(search) {
    return queueQueries(
        `${API_URL}/admin/recipes/autocomplete/${search}/`,
        'GET'
    )
}
function getAdvices(search) {
    return queueQueries(
        `${API_URL}/admin/advices/autocomplete/${search}/`,
        'GET'
    )
}
function getQuizzs(search) {
    return queueQueries(
        `${API_URL}/admin/quizzs/autocomplete/${search}/`,
        'GET'
    )
}
function getRewards(search) {
    return queueQueries(`${API_URL}/admin/cards/autocomplete/${search}/`, 'GET')
}
function getRewardsContent(search) {
    return queueQueries(
        `${API_URL}/admin/rewards/autocomplete/${search}/`,
        'GET'
    )
}
function getChallenges(search) {
    return queueQueries(
        `${API_URL}/admin/challenges/autocomplete/${search}/`,
        'GET'
    )
}
function getCoachs(search) {
    return queueQueries(
        `${API_URL}/admin/coachs/autocomplete/${search}/`,
        'GET'
    )
}
function getPrograms(search) {
    return queueQueries(
        `${API_URL}/admin/programs/autocomplete/${search}/`,
        'GET'
    )
}
function getObjectives(search) {
    return queueQueries(
        `${API_URL}/admin/objectives/autocomplete/${search}/`,
        'GET'
    )
}
function getCities(search, lang = 'fr') {
    return queueQueries(`${API_URL}/city/${lang}/${search}/`, 'GET')
}

export const AutoComplete = {
    getCardsByType,
    getArticles,
    getRecipes,
    getAdvices,
    getQuizzs,
    getRewards,
    getRewardsContent,
    getChallenges,
    getCoachs,
    getPrograms,
    getObjectives,
    getCities,
}

import { t } from '@app/utils'
import * as constants from '@app/constants'
import { Card, Customers, Study } from '@app/api'
import { addCSRFToken, deepCopy, getDateObjFromYMD } from '@app/core'

var userThemes = deepCopy(constants.custo.CARDS_TYPES)
for (var usert in userThemes) {
    if (
        constants.custo.CARDS_TYPES_USER_IGNORE.indexOf(userThemes[usert].key) >
        -1
    ) {
        delete userThemes[usert]
    }
}

const user_ESTABLISHMENT = {
    filter: ['customer'],
    search: { label: 'ID, KPI, Établissement' },
    tableHead: [
        { key: 'name', label: 'entreprise' },
        { key: 'parent', label: 'entreprise mère' },
        { key: 'totalHads', label: 'soignants' },
        { key: 'activeUsers[-]totalHads', label: 'patients' },
    ],
    tableOptions: [
        {
            key: 'edit',
            label: 'Éditer',
            kind: 'web',
            action: addCSRFToken('/users/establishments/:id'),
        },
        {
            key: 'deleteEstablishment',
            label: 'Supprimer',
            kind: 'confirm',
            action: Customers.deleteCustomer,
        },
    ],
    /*'tableAllOptions': {
        'label': 'ÉTABLISSEMENTS SÉLECTIONNÉS',
        'list': [
            {'key': 'deleteEstablishment', 'label': 'Supprimer', 'kind': 'confirm', 'action': Customers.deleteCustomer},
        ]
    },*/
    header: ['Configurer un établissement'],
    forbiddenDeletion: {
        dependsOn: [
            {
                field: 'totalChildren',
                is: '0',
            },
            {
                field: 'activeUsers',
                is: '0',
            },
        ],
    },
    form: {
        id: {
            field: 'hidden',
            value: '',
        },
        template: {
            field: 'hidden',
            value: 'establishments',
        },
        type: {
            name: 'type',
            type: 'boolean',
            label: 'Type',
            field: 'radio',
            sending: false,
            choice: {
                Mère: 1,
                Fille: 0,
            },
            value: 1,
            display: {
                mode: 'full',
                border: 'bottom',
            },
            dependsOn: [
                {
                    field: 'activeUsers',
                    is: '0',
                },
                {
                    field: 'totalChildren',
                    is: '0',
                },
            ],
        },
        activeUsers: {
            name: 'activeUsers',
            type: 'int',
            label: '',
            field: 'hidden',
            sending: false,
            value: '0',
        },
        totalChildren: {
            name: 'totalChildren',
            type: 'int',
            label: '',
            field: 'hidden',
            sending: false,
            value: '0',
        },
        name: {
            name: 'name',
            type: 'string',
            label: 'Établissement mère',
            field: 'text',
            placeholder: 'Renseignez ici le nom de l‘entreprise',
            value: '',
            display: {
                mode: 'half',
            },
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez saisir le nom de l‘établissement mère',
                i18nMessage: t(
                    'Veuillez saisir le nom de l‘établissement mère'
                ),
            },
            dependsOn: {
                field: 'type',
                value: 1,
            },
        },
        parent: {
            name: 'parent',
            type: 'customer',
            label: 'Établissement mère',
            field: 'dropdown',
            list: 'dynamic',
            deletable: true,
            placeholder: '',
            value: '',
            display: {
                mode: 'half',
            },
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez saisir un établissement mère',
                i18nMessage: t('Veuillez saisir un établissement mère'),
            },
            dependsOn: {
                field: 'type',
                value: 0,
            },
        },
        child: {
            name: 'name',
            substitute: 'name',
            type: 'string',
            label: 'Établissement fille',
            field: 'text',
            placeholder: 'Renseignez ici le nom de l‘entreprise fille',
            value: '',
            display: {
                mode: 'half',
            },
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez saisir le nom de l‘établissement fille',
                i18nMessage: t(
                    'Veuillez saisir le nom de l‘établissement fille'
                ),
            },
            dependsOn: {
                field: 'type',
                value: 0,
            },
        },
        country: {
            name: 'country',
            type: 'int',
            label: 'Pays',
            field: 'dropdown',
            placeholder: 'Faites dérouler la liste',
            list: constants.custo.NATIONS,
            value: 'FR',
            display: {
                mode: 'half',
            },
            dependsOn: {
                field: 'type',
                value: 0,
            },
        },
        expiration: {
            name: 'expiration',
            substitute: 'expire',
            type: 'date',
            label: 'Date d‘expiration',
            field: 'date',
            placeholder: { day: 'jj', week: 'mm', year: 'aaaa' },
            value: getDateObjFromYMD('20301231'),
            validation: {
                mandatory: true,
                isValid: false,
                type: 'is-date',
                message: 'Veuillez renseigner une date d‘expiration valide',
                i18nMessage: t(
                    'Veuillez renseigner une date d‘expiration valide'
                ),
            },
            display: {
                mode: 'full',
                border: 'bottom',
            },
            dependsOn: {
                field: 'type',
                value: 1,
            },
        },
        lifenDatabaseReference: {
            name: 'lifenDatabaseReference',
            type: 'lyfen',
            label: 'Référence Lifen',
            field: 'dropdown',
            deletable: true,
            placeholder: '',
            value: '',
            display: {
                mode: 'full',
            },
            beforeSend: {
                ifempty: null,
            },
            validation: {
                mandatory: false,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez saisir une référence lifen',
                i18nMessage: t('Veuillez saisir une référence lifen'),
            },
        },
        activateLifenEHRExport: {
            name: 'activateLifenEHRExport',
            type: 'boolean',
            label: 'export DPI Lifen',
            field: 'radio',
            choice: {
                Oui: true,
                Non: false,
            },
            placeholder: '',
            value: false,
            display: {
                mode: 'full',
            },
        },
        FINESSGeo: {
            name: 'FINESSGeo',
            type: 'int',
            label: 'FINESS Géographique',
            field: 'int',
            value: '',
            placeholderInner: '9 caractères numériques',
            validation: {
                mandatory: false,
                isValid: false,
                type: 'exact-length/9',
                message: 'Veuillez renseigner un numéro à 9 chiffres',
                i18nMessage: t('Veuillez renseigner un numéro à 9 chiffres'),
            },
            beforeSend: {
                ifempty: 'remove',
            },
            display: {
                mode: 'half',
            },
            dependsOn: {
                field: 'type',
                value: 0,
            },
        },
        FINESSLegal: {
            name: 'FINESSLegal',
            type: 'int',
            label: 'FINESS Juridique',
            field: 'int',
            value: '',
            placeholderInner: '9 caractères numériques',
            validation: {
                mandatory: false,
                isValid: false,
                type: 'exact-length/9',
                message: 'Veuillez renseigner un numéro à 9 chiffres',
                i18nMessage: t('Veuillez renseigner un numéro à 9 chiffres'),
            },
            beforeSend: {
                ifempty: 'remove',
            },
            display: {
                mode: 'half',
            },
            dependsOn: {
                field: 'type',
                value: 0,
            },
        },
        childexpiration: {
            name: 'expiration',
            substitute: 'expire',
            type: 'date',
            label: "Date d'expiration",
            field: 'readonly',
            sending: true,
            placeholder: { day: 'jj', week: 'mm', year: 'aaaa' },
            value: '',
            display: {
                mode: 'full',
                border: 'bottom',
            },
            dependsOn: {
                field: 'type',
                value: 0,
            },
        },
        edsConsentDefault: {
            name: 'edsConsentDefault',
            type: 'booleanString',
            label: 'Consentement santé par défaut',
            field: 'radio',
            choice: {
                'Accepté': 1,
                'Refusé': 0,
                'À demander': null,
            },
            placeholder: '',
            value: null,
            beforeSend: {
                ifempty: null,
            },
            display: {
                mode: 'full',
            },
        },
        remoteMonitoringConsent: {
            name: 'remoteMonitoringConsent',
            type: 'booleanString',
            label: 'Consentement télésurveillance',
            field: 'radio',
            choice: {
                Activé: 1,
                Désactivé: 0,
            },
            placeholder: '',
            value: 0,
            // beforeSend: {
            // 	ifempty: null,
            // },
            atLoad: {
                readOnly: {
                    ifExistingEntry: true,
                },
            },
            display: {
                mode: 'full',
            },
        },
        customerModules: {
            name: 'customerModules',
            type: 'linked-selects',
            label: 'Modules',
            field: 'linked-selects',
            choice: {
                Activé: 1,
                Désactivé: 0,
            },
            choiceList: [
                {
                    label: 'Telesuivi',
                    id: 'monitoring',
                    list: constants.custo.MOD_SETTINGS_MONITORING,
                    checked: false,
                    withDefaultOpt: true,
                },
                {
                    label: 'Etp',
                    id: 'etp',
                    list: constants.custo.MOD_SETTINGS_ETP,
                    checked: false,
                    withDefaultOpt: true,
                },
                {
                    label: 'Rapport de santé',
                    id: 'studySurveyResult',
                    list: null,
                    checked: false,
                },
                {
                    label: 'Alertes médicale',
                    id: 'studyAlertMedicalTeam',
                    list: null,
                    checked: false,
                },
                {
                    label: 'Traitement',
                    id: 'treatment',
                    list: null,
                    checked: false,
                },
                {
                    label: 'Historique',
                    id: 'history',
                    list: null,
                    checked: false,
                },
                {
                    label: 'Etude',
                    id: 'study',
                    list: constants.custo.CARDS_MEDICAL_STUDIES,
                    checked: false,
                    withDefaultOpt: true,
                },
                {
                    label: 'Vidéo Conférence',
                    id: 'videoConference',
                    list: null,
                    checked: false,
                },
                {
                    label: 'Orientation',
                    id: 'orientation',
                    list: null,
                    checked: false,
                },
                {
                    label: 'Etude clinique',
                    id: 'researchStudy',
                    list: 'dynamic',
                    listAPI: Study.getStudyResearch,
                    listParams: [{ type: 'dynamic', key: 'id' }],
                    tempList: [],
                    checked: false,
                    withActiveLabel: true,
                    activeKey: { name: 'status', value: 'ACTIVE' },
                },
            ],
            linkedChoice: [],
            value: '',
            dependsOn: {
                field: 'type',
                value: 0,
            },
        },
    },
}

export { user_ESTABLISHMENT }

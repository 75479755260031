import { t, h } from '@app/utils'
import { allRoutes, addCSRFToken, isDefined } from '@app/core'
import { Link } from '@app/elements'

import { UserMenu } from '@app/layouts/usermenu'

import icoAddEntry from '@app/img/ico/add-icon.png'
import icoAddEntryx2 from '@app/img/ico/add-icon@2x.png'

import icoCards from '@app/img/ico/left-menu/ico-cartes.png'
import icoCardsx2 from '@app/img/ico/left-menu/ico-cartes@2x.png'
import icoCardsGrey from '@app/img/ico/left-menu/ico-cartes-grey.png'
import icoCardsGreyx2 from '@app/img/ico/left-menu/ico-cartes-grey@2x.png'

import icoPrograms from '@app/img/ico/left-menu/ico-prog.png'
import icoProgramsx2 from '@app/img/ico/left-menu/ico-prog@2x.png'
import icoProgramsGrey from '@app/img/ico/left-menu/ico-prog-grey.png'
import icoProgramsGreyx2 from '@app/img/ico/left-menu/ico-prog-grey@2x.png'

import icoRecipes from '@app/img/ico/left-menu/ico-recettes.png'
import icoRecipesx2 from '@app/img/ico/left-menu/ico-recettes@2x.png'
import icoRecipesGrey from '@app/img/ico/left-menu/ico-recettes-grey.png'
import icoRecipesGreyx2 from '@app/img/ico/left-menu/ico-recettes-grey@2x.png'

import icoUsers from '@app/img/ico/left-menu/ico-users.png'
import icoUsersx2 from '@app/img/ico/left-menu/ico-users@2x.png'
import icoUsersGrey from '@app/img/ico/left-menu/ico-users-grey.png'
import icoUsersGreyx2 from '@app/img/ico/left-menu/ico-users-grey@2x.png'

import icoCustomers from '@app/img/ico/left-menu/ico-entreprises.png'
import icoCustomersx2 from '@app/img/ico/left-menu/ico-entreprises@2x.png'
import icoCustomersGrey from '@app/img/ico/left-menu/ico-entreprises-grey.png'
import icoCustomersGreyx2 from '@app/img/ico/left-menu/ico-entreprises-grey@2x.png'

module.exports = {
    initView: function () {
        let view = (props, actions) => (
            <div style={{ padding: '20px 0px' }}>
                <div>
                    <div class="btzInstance">
                        <p class="btzInstance-title">
                            {isDefined(localStorage.getItem('overrideapi')) &&
                            localStorage.getItem('overrideapi') !== 'null'
                                ? localStorage
                                      .getItem('overrideapi')
                                      .split('-')[0]
                                : 'theraflow'}
                        </p>
                    </div>
                    {props.currentPage == 'content' && (
                        <ul>
                            <li class={props.page == 'cards' ? 'active' : ''}>
                                <div>
                                    <img
                                        class="activated"
                                        src={icoCards}
                                        srcset={`${icoCardsx2} 2x`}
                                        alt=""
                                    />
                                    <img
                                        class="not-activated"
                                        src={icoCardsGrey}
                                        srcset={`${icoCardsGreyx2} 2x`}
                                        alt=""
                                    />
                                </div>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes['private.cards'].pathname
                                    )}
                                >
                                    {t('Cartes')}
                                </Link>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes[
                                            'private.cards.edit'
                                        ].pathname.replace(':id', 'null')
                                    )}
                                >
                                    <img
                                        src={icoAddEntry}
                                        srcset={`${icoAddEntryx2} 2x`}
                                        alt=""
                                    />
                                </Link>
                            </li>
                            <li
                                class={
                                    [
                                        'programs',
                                        'programs sessions',
                                        'programs steps',
                                    ].indexOf(props.page) > -1
                                        ? 'active'
                                        : ''
                                }
                            >
                                <div>
                                    <img
                                        class="activated"
                                        src={icoPrograms}
                                        srcset={`${icoProgramsx2} 2x`}
                                        alt=""
                                    />
                                    <img
                                        class="not-activated"
                                        src={icoProgramsGrey}
                                        srcset={`${icoProgramsGreyx2} 2x`}
                                        alt=""
                                    />
                                </div>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes['private.programs.etp']
                                            .pathname
                                    )}
                                >
                                    {t('Programmes')}
                                </Link>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes[
                                            'private.programs.etp.edit'
                                        ].pathname.replace(':id', 'null')
                                    )}
                                >
                                    <img
                                        src={icoAddEntry}
                                        srcset={`${icoAddEntryx2} 2x`}
                                        alt=""
                                    />
                                </Link>
                            </li>
                            <li class={props.page == 'recipes' ? 'active' : ''}>
                                <div>
                                    <img
                                        class="activated"
                                        src={icoRecipes}
                                        srcset={`${icoRecipesx2} 2x`}
                                        alt=""
                                    />
                                    <img
                                        class="not-activated"
                                        src={icoRecipesGrey}
                                        srcset={`${icoRecipesGreyx2} 2x`}
                                        alt=""
                                    />
                                </div>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes['private.recipes'].pathname
                                    )}
                                >
                                    {t('Recettes')}
                                </Link>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes[
                                            'private.recipes.edit'
                                        ].pathname.replace(':id', 'null')
                                    )}
                                >
                                    <img
                                        src={icoAddEntry}
                                        srcset={`${icoAddEntryx2} 2x`}
                                        alt=""
                                    />
                                </Link>
                            </li>
                        </ul>
                    )}
                    {props.currentPage == 'users' && (
                        <ul>
                            <li class={props.page == 'nurses' ? 'active' : ''}>
                                <div>
                                    <img
                                        class="activated"
                                        src={icoUsers}
                                        srcset={`${icoUsersx2} 2x`}
                                        alt=""
                                    />
                                    <img
                                        class="not-activated"
                                        src={icoUsersGrey}
                                        srcset={`${icoUsersGreyx2} 2x`}
                                        alt=""
                                    />
                                </div>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes['private.users.nurses']
                                            .pathname
                                    )}
                                >
                                    {t('Soignants')}
                                </Link>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes[
                                            'private.users.nurses.edit'
                                        ].pathname.replace(':id', 'null')
                                    )}
                                >
                                    <img
                                        src={icoAddEntry}
                                        srcset={`${icoAddEntryx2} 2x`}
                                        alt=""
                                    />
                                </Link>
                            </li>
                            <li
                                class={props.page == 'patients' ? 'active' : ''}
                            >
                                <div>
                                    <img
                                        class="activated"
                                        src={icoUsers}
                                        srcset={`${icoUsersx2} 2x`}
                                        alt=""
                                    />
                                    <img
                                        class="not-activated"
                                        src={icoUsersGrey}
                                        srcset={`${icoUsersGreyx2} 2x`}
                                        alt=""
                                    />
                                </div>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes['private.users.patients']
                                            .pathname
                                    )}
                                >
                                    {t('Patients')}
                                </Link>
                                <dummy></dummy>
                            </li>
                            <li
                                class={
                                    props.page.indexOf('establishments') > -1
                                        ? 'active'
                                        : ''
                                }
                            >
                                <div>
                                    <img
                                        class="activated"
                                        src={icoCustomers}
                                        srcset={`${icoCustomersx2} 2x`}
                                        alt=""
                                    />
                                    <img
                                        class="not-activated"
                                        src={icoCustomersGrey}
                                        srcset={`${icoCustomersGreyx2} 2x`}
                                        alt=""
                                    />
                                </div>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes[
                                            'private.users.establishments'
                                        ].pathname
                                    )}
                                >
                                    {t('Établissements')}
                                </Link>
                                <Link
                                    to={addCSRFToken(
                                        allRoutes[
                                            'private.users.establishments.edit'
                                        ].pathname.replace(':id', 'null')
                                    )}
                                >
                                    <img
                                        src={icoAddEntry}
                                        srcset={`${icoAddEntryx2} 2x`}
                                        alt=""
                                    />
                                </Link>
                            </li>
                        </ul>
                    )}
                    <div
                        style={{
                            position: 'absolute',
                            bottom: '20px',
                            left: '65px',
                        }}
                    >
                        <UserMenu display={'connected'}></UserMenu>
                    </div>
                </div>
            </div>
        )
        return view
    },
}

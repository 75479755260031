import { settings } from '@app/core/settings'
import { handleErrors } from '@app/api/errors'
import { queueQueries, executeQuery } from '@app/api/controlleur'

const { API_URL } = settings

function getUser() {
    return queueQueries(`${API_URL}/user/`, 'GET')
}
function resiliateUser(id) {
    return queueQueries(`${API_URL}/admin/users/${id}/resiliate/`, 'PUT')
}
function clearTimeline() {
    return queueQueries(`${API_URL}/admin/settings/clear-timelines/`, 'DELETE')
}
function refreshUser(refresh) {
    return executeQuery(
        `${API_URL}/admin/session/refresh/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        false,
        true,
        refresh
    )
}
function destroySession() {
    return queueQueries(`${API_URL}/session/`, 'DELETE', null, false, true)
}
function deleteUser(id) {
    return queueQueries(`${API_URL}/admin/users/${id}/`, 'DELETE')
}
function deleteCustomer(id) {
    return queueQueries(`${API_URL}/admin/customers/${id}/`, 'DELETE')
}

function getUsers(page = 0, sorts = {}, filters = {}) {
    return queueQueries(
        `${API_URL}/admin/users/search/${page}/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        { sort: sorts, ...filters }
    )
}
function getCustomers(page = 0, sorts = {}, filters = {}) {
    return queueQueries(
        `${API_URL}/admin/customers/search/${page}/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        { sort: sorts, ...filters }
    )
}
function getNurses(page = 0, sorts = {}, filters = {}) {
    filters.role = [
        'ROLE_DOCTOR',
        'ROLE_MEDICAL_SUPERVISOR',
        'ROLE_MEDICAL_PROMOTER',
    ]
    return queueQueries(
        `${API_URL}/admin/users/search/${page}/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        { sort: sorts, ...filters }
    )
}
function getNursesAS(search = '') {
    return queueQueries(
        `${API_URL}/doctors/search/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        { search: search }
    )
}
function getPatients(page = 0, sorts = {}, filters = {}) {
    filters.theraflowPatient = true
    return queueQueries(
        `${API_URL}/admin/users/search/${page}/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        { sort: sorts, ...filters }
    )
}
function getEstablishments(page = 0, sorts = {}, filters = {}) {
    filters.hospital = true
    return queueQueries(
        `${API_URL}/admin/customers/search/${page}/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        { sort: sorts, ...filters }
    )
}

function editUser(id, data) {
    return queueQueries(
        `${API_URL}/admin/users/${id}/`,
        'PUT',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function editCustomer(id, data) {
    return queueQueries(
        `${API_URL}/admin/customers/${id}/`,
        'PUT',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function editCustomerScenario(id, idscenario, data) {
    return queueQueries(
        `${API_URL}/admin/customers/${id}/scenarios/${idscenario}/`,
        'PUT',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function editNurse(id, data) {
    return queueQueries(
        `${API_URL}/admin/users/${id}/`,
        'PUT',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function editPatient(id, data) {
    return queueQueries(
        `${API_URL}/admin/users/${id}/`,
        'PUT',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function editEstablishment(id, data) {
    return queueQueries(
        `${API_URL}/admin/customers/${id}/`,
        'PUT',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}

function addUser(data) {
    return queueQueries(
        `${API_URL}/admin/users/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function addCustomer(data) {
    return queueQueries(
        `${API_URL}/admin/customers/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function addCustomerScenario(id, data) {
    return queueQueries(
        `${API_URL}/admin/customers/${id}/scenarios/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function addNurse(data) {
    return queueQueries(
        `${API_URL}/admin/users/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function addPatient(data) {
    return queueQueries(
        `${API_URL}/admin/users/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function addEstablishment(data) {
    return queueQueries(
        `${API_URL}/admin/customers/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}

function getUserByKind(kind, id) {
    return queueQueries(`${API_URL}/admin/${kind}/${id}/`, 'GET')
}

function getUsersByLicense(page = 0, sorts = {}, filters = {}) {
    return queueQueries(
        `${API_URL}/admin/users/search/license/${page}/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        { sort: sorts, ...filters }
    )
}

function getUsersProfile(id) {
    return queueQueries(`${API_URL}/admin/users/${id}/profile/`, 'GET')
}

function getUsersLicenses(id) {
    return queueQueries(`${API_URL}/admin/users/${id}/licenses/`, 'GET')
}
function unarchivatePatient(id) {
    return queueQueries(`${API_URL}/admin/users/${id}/unarchive`, 'PUT')
}

function addChildren(id, email) {
    return queueQueries(
        `${API_URL}/admin/users/${id}/children/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        { email: email }
    )
}
function removeChildren(id, email) {
    return queueQueries(
        `${API_URL}/admin/users/${id}/children/`,
        'DELETE',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        { email: email }
    )
}

function resetPasswordUser(id) {
    return queueQueries(`${API_URL}/admin/users/${id}/password/`, 'POST')
}
function clearTimelineUser(id) {
    return queueQueries(`${API_URL}/admin/users/clear-timelines/${id}/`, 'POST')
}

function verifyRPPS(rpps) {
    return queueQueries(`${API_URL}/doctors/search/?search=${rpps}`, 'POST')
}

function unlockAccount(id) {
    return queueQueries(
        `${API_URL}/admin/users/${id}/`,
        'PUT',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        { security: { unblockLogin: true } }
    )
}

function submitNablaSuborgId(nablaSubOrganizationId, userId) {
    return queueQueries(
        `${API_URL}/admin/users/${userId}/nabla-suborg-id/`,
        'PUT',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        { nablaSubOrganizationId: nablaSubOrganizationId }
    )
}

function sendInvitationUsingId(id) {
    return queueQueries(`${API_URL}/admin/users/${id}/send-invitation/`, 'GET')
}

export const User = {
    refreshUser,
    destroySession,
    resiliateUser,
    clearTimeline,
    getUser,
    deleteUser,
    deleteCustomer,
    getUsers,
    getCustomers,
    getNurses,
    getNursesAS,
    getPatients,
    getEstablishments,
    editUser,
    editCustomer,
    editCustomerScenario,
    editNurse,
    editPatient,
    editEstablishment,
    getUserByKind,
    getUsersByLicense,
    getUsersProfile,
    getUsersLicenses,
    addCustomer,
    addCustomerScenario,
    addNurse,
    addPatient,
    addEstablishment,
    addUser,
    addChildren,
    removeChildren,
    resetPasswordUser,
    clearTimelineUser,
    verifyRPPS,
    unlockAccount,
    submitNablaSuborgId,
    sendInvitationUsingId,
    unarchivatePatient,
}

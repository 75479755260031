import { h, Component } from '@app/utils'

import { Grid, Navbar, Enter } from '@app/elements'
import { allRoutes } from '@app/core'
import * as constants from '@app/constants'

import { TopLogoView } from '@app/layouts/toplogo'
import { TopMenuView } from '@app/layouts/topmenu'
import { LeftMenuView } from '@app/layouts/leftmenu'

import './index.scss'

const actions = {
    goBack: () => (state) => {
        window.history.back()
    },
}

const view = (state, actions) => (props, children) => (
    <div key={'register'} class={props.page}>
        <Enter
            time={constants.ANIM_DURATION}
            easing={constants.ANIM_EASING}
            css={constants.ANIM_CSS}
        >
            <Grid classes={'btzHeader btzHeader-btzNoMediaQuery'}>
                <Grid.Row classes={'btzHeaderWrapper'}>
                    <Grid.Col x={12} mx={'auto'} classes={'p-0'}>
                        <Navbar>
                            <Navbar.Section classes="btzHeader-btzHeaderLeftHand">
                                <TopLogoView
                                    {...props}
                                    color={'black'}
                                    redirectTo={allRoutes['index'].pathname}
                                    customer={props.customer || 'betterise'}
                                ></TopLogoView>
                            </Navbar.Section>

                            <Navbar.Section classes="btzHeader-btzHeaderRightHand">
                                <TopMenuView
                                    {...props}
                                    customer={props.customer || 'betterise'}
                                ></TopMenuView>
                            </Navbar.Section>
                        </Navbar>
                    </Grid.Col>
                </Grid.Row>
            </Grid>
            <Grid
                classes={'btzWrapper btzWrapperFullHeight btzVerticalAlign'}
                style={{ padding: '0px' }}
            >
                <Grid.Row
                    classes={
                        'btzWrapper-verticalAlignContent' +
                        (props.howthatwork
                            ? ' btzWrapper-verticalAlignContent-minHeight'
                            : '')
                    }
                >
                    <Grid.Col
                        x={12}
                        mx={'auto'}
                        style={{ position: 'relative' }}
                    >
                        <div class="btzMainPage btzMainPageLeft">
                            <LeftMenuView
                                {...props}
                                customer={props.customer || 'betterise'}
                            ></LeftMenuView>
                        </div>
                        <div class="btzMainPage btzMainPageCenter">
                            {children}
                        </div>
                    </Grid.Col>
                </Grid.Row>
            </Grid>
        </Enter>
    </div>
)

export default Component({}, actions, view, 'layout')

import { queueQueries } from '@app/api/controlleur'
import { settings } from '@app/core/settings'

const { API_URL } = settings

function updateDoctorNursesTeam(data) {
    return queueQueries(
        `${API_URL}/medical/supervisor`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}

export const Operations = {
    updateDoctorNursesTeam,
}

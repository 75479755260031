import { settings } from '@app/core/settings'
import { queueQueries } from '@app/api/controlleur'

const { API_URL } = settings

function getStudyResearch(customerId) {
    return queueQueries(
        `${API_URL}/admin/theraflow/research-study?customer=${customerId}`,
        'GET'
    )
}

export const Study = {
    getStudyResearch,
}

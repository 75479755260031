import { h, classMerge } from '@app/utils'

import './index.scss'
/**
 * Avatar
 * @see https://picturepan2.github.io/spectre/components.html#avatars
 * @param {any} props All properties passed to element
 * @param {any} props.src Image path to be displayed
 * @param {any} props.size Size of element
 * @param {any} props.alt Alternative text describing the image
 * @param {any} children All children elements to be nested inside
 * @return vNode
 */
const Avatar = (props, children) => {
    const {
        src = '',
        srcset = '',
        size = 'xl',
        alt = '',
        classes,
        ...otherProps
    } = props

    const allClasses = classMerge([
        'btzAvatar',
        `avatar-${size}`,
        'centered',
        classes,
    ])

    return (
        <figure class={allClasses} {...otherProps}>
            <img src={src} srcset={srcset} alt={alt} />
            {children}
        </figure>
    )
}

/**
 * Alfred' Avatar
 * @return vNode with Alfred image in
 */
const Alfred = (props) => (
    <Avatar
        {...props}
        src={`${process.env.APP_WEB_URL}/__custo/${
            props.customer
                ? props.customer.toLowerCase()
                : process.env.APP_CUSTOMISATION.toLowerCase()
        }/avatar/my-major.png`}
        srcset={`${process.env.APP_WEB_URL}/__custo/${
            props.customer
                ? props.customer.toLowerCase()
                : process.env.APP_CUSTOMISATION.toLowerCase()
        }/avatar/my-major@2x.png 2x`}
        size={'xl'}
        alt="Alfred"
    />
)

Avatar.Alfred = Alfred

export { Avatar }

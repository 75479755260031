import { t } from '@app/utils'
import { allRoutes, isDefined } from '@app/core'
import { User } from '@app/api'

import { handleErrors, displayErrors } from '@app/api/errors'
import { handleHeaders } from '@app/api/headers'
import { Loader } from '@app/api/loader'

const Sanitize = function (string, soft = false) {
    if (!isDefined(string) || typeof string !== 'string') {
        return string
    }
    //
    if (!soft) {
        const map = {
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt;',
            '"': '&quot;',
            "'": '&#x27;',
            '/': '&#x2F;',
            '`': '&grave;',
        }
        // const reg = /[&<>"'/]/gi
        // const reg = /[&"'/]|(?! )<(?! )|(?! )>(?! )/gi
        const reg = /[&"'/]|(?! )<(?!=)(?! )|(?! )>(?!=)(?! )/gi
        return string.replace(reg, (match) => map[match])
    } else {
        const map = {
            '<': '&lt;',
            '>': '&gt;',
        }
        // const reg = /[<>]/gi
        // const reg = /(?! )<(?! )|(?! )>(?! )/gi
        const reg = /(?! )<(?!=)(?! )|(?! )>(?!=)(?! )/gi
        return string.replace(reg, (match) => map[match])
    }
}

function sanitizeData(data) {
    let cloneData = JSON.parse(JSON.stringify(data))
    if (typeof cloneData === 'object' || Array.isArray(cloneData)) {
        for (let key in cloneData) {
            if (data.hasOwnProperty(key) && cloneData[key]) {
                cloneData[key] = sanitizeData(cloneData[key])
            }
        }
    } else {
        cloneData = Sanitize(cloneData, true)
    }
    //
    return cloneData
}

const executeQuery = async (
    url,
    method,
    ctype,
    withLoader,
    withHeader,
    withErrors,
    withToken,
    mustBeQueued,
    datas
) => {
    console.log('url : ', url)
    if (url.indexOf('localhost') === -1) {
        let overrideapi = localStorage.getItem('overrideapi')
        let overrideinstance = localStorage.getItem('overrideinstance')
        console.log('executeQuery > url : ', url, overrideapi, overrideinstance)
        if (isDefined(overrideapi) && overrideapi !== 'null') {
            if (overrideinstance === 'release') {
                overrideapi = overrideapi.replace('-api', '-api-release')
            } else if (overrideinstance === 'dev') {
                overrideapi = 'theradmin.dev.resilience-secure.com/_api_'
            }

            if (url.indexOf('https') > -1) {
                url = url.replace(
                    /(https:|)(^|\/\/)(.*?\/)/g,
                    'https://' + overrideapi + '/'
                )
            } else {
                url = url.replace(
                    /(http:|)(^|\/\/)(.*?\/)/g,
                    'https://' + overrideapi + '/'
                )
            }
        }
    }
    //
    let token = localStorage.getItem('apitoken')
    if (withLoader) {
        Loader.addQuery()
    }
    let oHeader = null
    if (withToken) {
        if (ctype === null) {
            oHeader = {
                'X-Token': token,
            }
        } else {
            oHeader = {
                'X-Token': token,
                'Content-Type': ctype,
            }
        }
    } else if (ctype !== null) {
        oHeader = {
            'Content-Type': ctype,
        }
    }

    let oBody = null
    let totalSize = 0
    if (datas instanceof FormData) {
        oBody = datas
        for (var bod of oBody.entries()) {
            for (var entry in bod) {
                if (bod[entry].size !== undefined) {
                    totalSize += bod[entry].size
                }
            }
        }
    } else if (datas !== null) {
        oBody = JSON.stringify(sanitizeData(datas))
    }
    if (totalSize > 14000000) {
        displayErrors(
            t(
                'Le contenue de la requête est supérieur à 14M, veuillez envoyer moins de donnée ou une image moins grande!'
            ),
            5000
        )
        return false
    }

    return await fetch(url, {
        method: method,
        headers: new Headers(oHeader),
        body: oBody,
    })
        .then((response) => {
            if (withLoader) {
                Loader.removeQuery()
            }
            return response
        })
        .then((response) => {
            if (withHeader) {
                return handleHeaders(response)
            } else {
                return response
            }
        })
        .then((response) => {
            if (response.status === 204) {
                return {}
            }
            return response.json()
        })
        .then((response) => {
            response.isError = false
            if (withErrors) {
                return handleErrors(response)
            } else {
                return response
            }
        })
        .then((data) => {
            return { data: sanitizeData(data) }
        })
        // .then(data => ({ data }))
        .catch((error) => {
            Loader.removeQuery()
            if (String(error).indexOf('fetch') > -1) {
                error = t(
                    'Erreur de communication avec le serveur, veuillez réessayer.'
                )
            }
            displayErrors(error, 2000)
            return { error: error }
        })
    //.catch(error => ({ error }))
}

let onceRefreshAtOnce = false
const TIMER = 1000
function sleeping() {
    var promise = new Promise(function (resolve) {
        window.setTimeout(function () {
            resolve('sleep done.')
        }, TIMER)
    })
    return promise
}

const queueQueries = async (
    url,
    method,
    ctype = 'application/json',
    withLoader = true,
    withHeader = true,
    withErrors = true,
    withToken = true,
    mustBeQueued = true,
    datas = null
) => {
    let expiration = localStorage.getItem('expiration')
    if (expiration != false && expiration !== null && expiration !== 'null') {
        if (onceRefreshAtOnce) {
            return sleeping().then(() => {
                return queueQueries(
                    url,
                    method,
                    ctype,
                    withLoader,
                    withHeader,
                    withErrors,
                    withToken,
                    mustBeQueued,
                    datas
                )
            })
        } else {
            let expirationDate = expiration.split(' ')[0].split('-')
            let expirationTime = expiration
                .split(' ')[1]
                .split('.')[0]
                .split(':')
            let dateExpiration = new Date(
                Date.UTC(
                    expirationDate[0],
                    +expirationDate[1] - 1,
                    expirationDate[2],
                    expirationTime[0],
                    expirationTime[1],
                    expirationTime[2]
                )
            )
            let dateOffset = new Date().getTimezoneOffset()
            dateExpiration.setMinutes(dateExpiration.getMinutes() - dateOffset)

            let now = new Date()
            now.setMinutes(now.getMinutes() - dateOffset)

            if (dateExpiration.getTime() < now.getTime()) {
                let cb = function () {
                    return executeQuery(
                        url,
                        method,
                        ctype,
                        withLoader,
                        withHeader,
                        withErrors,
                        withToken,
                        mustBeQueued,
                        datas
                    )
                }
                return refreshSession(cb)
            } else {
                return executeQuery(
                    url,
                    method,
                    ctype,
                    withLoader,
                    withHeader,
                    withErrors,
                    withToken,
                    mustBeQueued,
                    datas
                )
            }
        }
    } else {
        return executeQuery(
            url,
            method,
            ctype,
            withLoader,
            withHeader,
            withErrors,
            withToken,
            mustBeQueued,
            datas
        )
    }
}

function refreshSession(callback) {
    let refreshToken = localStorage.getItem('refresh')
    onceRefreshAtOnce = true
    if (refreshToken) {
        return User.refreshUser({ refresh: refreshToken }).then((res) => {
            if (isDefined(res.data) && res.data.token && res.data.refresh) {
                localStorage.setItem('apitoken', res.data.token)
                localStorage.setItem('refresh', res.data.refresh)
                localStorage.setItem('expiration', res.data.expiration.date)
                onceRefreshAtOnce = false
                return callback()
            } else {
                window.location.href = allRoutes['index'].pathname
                return false
            }
        })
    } else {
        window.location.href = allRoutes['index'].pathname
        return false
    }
}

export { queueQueries }
export { executeQuery }

import { settings } from '@app/core/settings'
import { handleErrors } from '@app/api/errors'
import { queueQueries } from '@app/api/controlleur'
import { isObjEmpty } from '@app/core'

const { API_URL } = settings

function getCards(page = 0, sorts = {}, filters = {}) {
    let data = {}
    if (!isObjEmpty(sorts)) {
        data = { sort: sorts, ...filters }
    } else {
        data = { ...filters }
    }
    return queueQueries(
        `${API_URL}/admin/cards/search/${page}/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function getArticles(page = 0, sorts = {}, filters = {}) {
    let data = {}
    if (!isObjEmpty(sorts)) {
        data = { sort: sorts, ...filters }
    } else {
        data = { ...filters }
    }
    return queueQueries(
        `${API_URL}/admin/articles/search/${page}/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function getPrograms(page = 0, sorts = {}, filters = {}) {
    let data = {}
    if (!isObjEmpty(sorts)) {
        data = { sort: sorts, ...filters }
    } else {
        data = { ...filters }
    }
    return queueQueries(
        `${API_URL}/admin/programs/search/${page}/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function getRecipes(page = 0, sorts = {}, filters = {}) {
    let data = {}
    if (!isObjEmpty(sorts)) {
        data = { sort: sorts, ...filters }
    } else {
        data = { ...filters }
    }
    return queueQueries(
        `${API_URL}/admin/recipes/search/${page}/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function getChallenges(page = 0, sorts = {}, filters = {}) {
    let data = {}
    if (!isObjEmpty(sorts)) {
        data = { sort: sorts, ...filters }
    } else {
        data = { ...filters }
    }
    return queueQueries(
        `${API_URL}/admin/challenges/search/${page}/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function getAliments(page = 0, sorts = {}, filters = {}) {
    let data = {}
    if (!isObjEmpty(sorts)) {
        data = { sort: sorts, ...filters }
    } else {
        data = { ...filters }
    }
    return queueQueries(
        `${API_URL}/admin/ciqual/search/${page}/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function getCoachs(page = 0, sorts = {}, filters = {}) {
    let data = {}
    if (!isObjEmpty(sorts)) {
        data = { sort: sorts, ...filters }
    } else {
        data = { ...filters }
    }
    return queueQueries(
        `${API_URL}/admin/coachs/search/${page}/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function getEmailStories(page = 0, sorts = {}, filters = {}) {
    let data = {}
    if (!isObjEmpty(sorts)) {
        data = { sort: sorts, ...filters }
    } else {
        data = { ...filters }
    }
    return queueQueries(
        `${API_URL}/admin/email-story/search/${page}/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function getAlerts(page = 0, sorts = {}, filters = {}) {
    let data = {}
    if (!isObjEmpty(sorts)) {
        data = { sort: sorts, ...filters }
    } else {
        data = { ...filters }
    }
    return queueQueries(
        `${API_URL}/admin/alerts/search/${page}/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function getObjectives(page = 0, sorts = {}, filters = {}) {
    let data = {}
    if (!isObjEmpty(sorts)) {
        data = { sort: sorts, ...filters }
    } else {
        data = { ...filters }
    }
    return queueQueries(
        `${API_URL}/admin/objectives/search/${page}/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function getRewardActions(page = 0, sorts = {}, filters = {}) {
    let data = {}
    if (!isObjEmpty(sorts)) {
        data = { sort: sorts, ...filters, type: 10 }
    } else {
        data = { ...filters, type: 10 }
    }
    return queueQueries(
        `${API_URL}/admin/cards/search/${page}/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function getRewards(page = 0, sorts = {}, filters = {}) {
    let data = {}
    if (!isObjEmpty(sorts)) {
        data = { sort: sorts, ...filters }
    } else {
        data = { ...filters }
    }
    return queueQueries(
        `${API_URL}/admin/rewards/search/${page}/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function getGamifications(page = 0, sorts = {}, filters = {}) {
    let data = {}
    if (!isObjEmpty(sorts)) {
        data = { sort: sorts, ...filters }
    } else {
        data = { ...filters }
    }
    return queueQueries(
        `${API_URL}/admin/gamification/search/${page}/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function getGamificationPoints(page = 0, sorts = {}, filters = {}) {
    let data = {}
    if (!isObjEmpty(sorts)) {
        data = { sort: sorts, ...filters }
    } else {
        data = { ...filters }
    }
    return queueQueries(
        `${API_URL}/admin/gamification-point/search/${page}/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function getGamificationLevels(page = 0, sorts = {}, filters = {}) {
    let data = {}
    if (!isObjEmpty(sorts)) {
        data = { sort: sorts, ...filters }
    } else {
        data = { ...filters }
    }
    return queueQueries(
        `${API_URL}/admin/gamification-level/search/${page}/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function getCorpoWordsRh(page = 0, sorts = {}, filters = {}) {
    let data = {}
    if (!isObjEmpty(sorts)) {
        data = { sort: sorts, ...filters, type: 56 }
    } else {
        data = { ...filters, type: 56 }
    }
    return queueQueries(
        `${API_URL}/admin/cards/search/${page}/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function getCorpoCitations(page = 0, sorts = {}, filters = {}) {
    let data = {}
    if (!isObjEmpty(sorts)) {
        data = { sort: sorts, ...filters, type: 20 }
    } else {
        data = { ...filters, type: 20 }
    }
    return queueQueries(
        `${API_URL}/admin/cards/search/${page}/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function getCorpoEvaluations(page = 0, sorts = {}, filters = {}) {
    let data = {}
    if (!isObjEmpty(sorts)) {
        data = { sort: sorts, ...filters, type: 57 }
    } else {
        data = { ...filters, type: 57 }
    }
    return queueQueries(
        `${API_URL}/admin/cards/search/${page}/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}

function addCard(data) {
    return queueQueries(
        `${API_URL}/admin/cards/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function addArticle(data) {
    return queueQueries(
        `${API_URL}/admin/articles/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function addProgram(data) {
    return queueQueries(
        `${API_URL}/admin/programs/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function addRecipe(data) {
    return queueQueries(
        `${API_URL}/admin/recipes/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function addChallenge(data) {
    return queueQueries(
        `${API_URL}/admin/challenges/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function addAliment(data) {
    return queueQueries(
        `${API_URL}/admin/ciqual/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function addCoach(data) {
    return queueQueries(
        `${API_URL}/admin/coachs/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function addEmailStory(data) {
    return queueQueries(
        `${API_URL}/admin/email-story/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function addAlert(data) {
    return queueQueries(
        `${API_URL}/admin/alerts/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function addObjective(data) {
    return queueQueries(
        `${API_URL}/admin/objectives/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function addReward(data) {
    return queueQueries(
        `${API_URL}/admin/rewards/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function addGamification(data) {
    return queueQueries(
        `${API_URL}/admin/gamification/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function addGamificationPoint(data) {
    return queueQueries(
        `${API_URL}/admin/gamification-point/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function addGamificationLevel(data) {
    return queueQueries(
        `${API_URL}/admin/gamification-level/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}

function editCard(id, data) {
    return queueQueries(
        `${API_URL}/admin/cards/${id}/`,
        'PUT',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function editArticle(id, data) {
    return queueQueries(
        `${API_URL}/admin/articles/${id}/`,
        'PUT',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function editProgram(id, data) {
    return queueQueries(
        `${API_URL}/admin/programs/${id}/`,
        'PUT',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function editRecipe(id, data) {
    return queueQueries(
        `${API_URL}/admin/recipes/${id}/`,
        'PUT',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function editChallenge(id, data) {
    return queueQueries(
        `${API_URL}/admin/challenges/${id}/`,
        'PUT',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function editAliment(id, data) {
    return queueQueries(
        `${API_URL}/admin/ciqual/${id}/`,
        'PUT',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function editCoach(id, data) {
    return queueQueries(
        `${API_URL}/admin/coachs/${id}/`,
        'PUT',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function editEmailStory(id, data) {
    return queueQueries(
        `${API_URL}/admin/email-story/${id}/`,
        'PUT',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function editAlert(id, data) {
    return queueQueries(
        `${API_URL}/admin/alerts/${id}/`,
        'PUT',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function editObjective(id, data) {
    return queueQueries(
        `${API_URL}/admin/objectives/${id}/`,
        'PUT',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function editReward(id, data) {
    return queueQueries(
        `${API_URL}/admin/rewards/${id}/`,
        'PUT',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function editGamification(id, data) {
    return queueQueries(
        `${API_URL}/admin/gamification/${id}/`,
        'PUT',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function editGamificationPoint(id, data) {
    return queueQueries(
        `${API_URL}/admin/gamification-point/${id}/`,
        'PUT',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function editGamificationLevel(id, data) {
    return queueQueries(
        `${API_URL}/admin/gamification-level/${id}/`,
        'PUT',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}

function getCardByKind(kind, id) {
    return queueQueries(`${API_URL}/admin/${kind}/${id}/`, 'GET')
}
function getCardStatisticsByKind(kind, id) {
    return queueQueries(`${API_URL}/admin/${kind}/results/${id}/`, 'GET')
}
function getCardStatisticsByKindAndPeriod(kind, id, ymd, period) {
    return queueQueries(
        `${API_URL}/admin/${kind}/${id}/result/${ymd}/${period}/`,
        'GET'
    )
}
function getCard(id) {
    return queueQueries(`${API_URL}/admin/cards/${id}/`, 'GET')
}
function getArticle(id) {
    return queueQueries(`${API_URL}/admin/articles/${id}/`, 'GET')
}
function getProgram(id) {
    return queueQueries(`${API_URL}/admin/programs/${id}/`, 'GET')
}
function getRecipe(id) {
    return queueQueries(`${API_URL}/admin/recipes/${id}/`, 'GET')
}
function getChallenge(id) {
    return queueQueries(`${API_URL}/admin/challenges/${id}/`, 'GET')
}
function getAliment(id) {
    return queueQueries(`${API_URL}/admin/ciqual/${id}/`, 'GET')
}
function getCoach(id) {
    return queueQueries(`${API_URL}/admin/coachs/${id}/`, 'GET')
}
function getEmailStory(id) {
    return queueQueries(`${API_URL}/admin/email-story/${id}/`, 'GET')
}
function getAlert(id) {
    return queueQueries(`${API_URL}/admin/alerts/${id}/`, 'GET')
}
function getObjective(id) {
    return queueQueries(`${API_URL}/admin/objectives/${id}/`, 'GET')
}
function getReward(id) {
    return queueQueries(`${API_URL}/admin/rewards/${id}/`, 'GET')
}
function getGamification(id) {
    return queueQueries(`${API_URL}/admin/gamification/${id}/`, 'GET')
}
function getGamificationPoint(id) {
    return queueQueries(`${API_URL}/admin/gamification-point/${id}/`, 'GET')
}
function getGamificationLevel(id) {
    return queueQueries(`${API_URL}/admin/gamification-level/${id}/`, 'GET')
}

function deleteCard(id) {
    return queueQueries(`${API_URL}/admin/cards/${id}/`, 'DELETE')
}
function deleteArticle(id) {
    return queueQueries(`${API_URL}/admin/articles/${id}/`, 'DELETE')
}
function deleteProgram(id) {
    return queueQueries(`${API_URL}/admin/programs/${id}/`, 'DELETE')
}
function deleteRecipe(id) {
    return queueQueries(`${API_URL}/admin/recipes/${id}/`, 'DELETE')
}
function deleteChallenge(id) {
    return queueQueries(`${API_URL}/admin/challenges/${id}/`, 'DELETE')
}
function deleteAliment(id) {
    return queueQueries(`${API_URL}/admin/ciqual/${id}/`, 'DELETE')
}
function deleteCoach(id) {
    return queueQueries(`${API_URL}/admin/coachs/${id}/`, 'DELETE')
}
function deleteEmailStory(id) {
    return queueQueries(`${API_URL}/admin/email-story/${id}/`, 'DELETE')
}
function deleteAlert(id) {
    return queueQueries(`${API_URL}/admin/alerts/${id}/`, 'DELETE')
}
function deleteObjective(id) {
    return queueQueries(`${API_URL}/admin/objectives/${id}/`, 'DELETE')
}
function deleteReward(id) {
    return queueQueries(`${API_URL}/admin/rewards/${id}/`, 'DELETE')
}
function deleteGamification(id) {
    return queueQueries(`${API_URL}/admin/gamification/${id}/`, 'DELETE')
}
function deleteGamificationPoint(id) {
    return queueQueries(`${API_URL}/admin/gamification-point/${id}/`, 'DELETE')
}
function deleteGamificationLevel(id) {
    return queueQueries(`${API_URL}/admin/gamification-level/${id}/`, 'DELETE')
}

function sendCardForm(id, data) {
    return queueQueries(
        `${API_URL}/admin/cards/${id}/forms/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function getKPI() {
    return queueQueries(`${API_URL}/admin/cards/kpis/`, 'GET')
}
function getDataTags() {
    return queueQueries(`${API_URL}/admin/cards/datatags/`, 'GET')
}
function getCiqualFamily() {
    return queueQueries(`${API_URL}/admin/ciqual/family/`, 'GET')
}

function getCiqual(search) {
    return queueQueries(
        `${API_URL}/admin/ciqual/autocomplete/${search}/`,
        'GET'
    )
}
function sendMedia(file) {
    let formData = new FormData()
    formData.append('media', file)
    return queueQueries(
        `${API_URL}/admin/medias/`,
        'POST',
        null,
        true,
        true,
        true,
        true,
        true,
        formData
    )
}
function sendVideo(data) {
    return queueQueries(
        `${API_URL}/admin/medias/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}

function resetCardType(userId, types) {
    return queueQueries(
        `${API_URL}/admin/users/${userId}/remove-cards/${types}/`,
        'POST'
    )
}

export const Card = {
    getCardByKind,
    getCardStatisticsByKind,
    getCardStatisticsByKindAndPeriod,
    getCards,
    addCard,
    editCard,
    getCard,
    deleteCard,
    getArticles,
    addArticle,
    editArticle,
    getArticle,
    deleteArticle,
    getPrograms,
    addProgram,
    editProgram,
    getProgram,
    deleteProgram,
    getRecipes,
    getRewardActions,
    addRecipe,
    editRecipe,
    getRecipe,
    deleteRecipe,
    getChallenges,
    addChallenge,
    editChallenge,
    getChallenge,
    deleteChallenge,
    getAliments,
    addAliment,
    editAliment,
    getAliment,
    deleteAliment,
    getCoachs,
    addCoach,
    editCoach,
    getCoach,
    deleteCoach,
    getEmailStories,
    addEmailStory,
    editEmailStory,
    getEmailStory,
    deleteEmailStory,
    getAlerts,
    addAlert,
    editAlert,
    getAlert,
    deleteAlert,
    getObjectives,
    addObjective,
    editObjective,
    getObjective,
    deleteObjective,
    getRewards,
    addReward,
    editReward,
    getReward,
    deleteReward,
    getGamifications,
    addGamification,
    editGamification,
    getGamification,
    deleteGamification,
    getGamificationPoints,
    addGamificationPoint,
    editGamificationPoint,
    getGamificationPoint,
    deleteGamificationPoint,
    getGamificationLevels,
    getCorpoWordsRh,
    getCorpoCitations,
    getCorpoEvaluations,
    addGamificationLevel,
    editGamificationLevel,
    getGamificationLevel,
    deleteGamificationLevel,
    sendCardForm,
    getKPI,
    getDataTags,
    getCiqualFamily,
    getCiqual,
    sendMedia,
    sendVideo,
    resetCardType,
}
